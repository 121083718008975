import { ConEdRequestor, ConEdSupervisor, ConedUser, UsersSearchParams } from 'types/Common/User';

import { APPROVE } from 'Constants/user';
import { NotificationsObject } from 'Containers/Profile/components/UserNotifications';
import { User } from 'Models/APITypes';
import ApiService, { API } from 'Services/HttpService';
import { objectValuesFilter } from 'Utils/valueFilter';

import { APP_API } from './AppAPI.service';

class UsersAPI {
  #filterParams = (params: API.RequestBody) => {
    return params instanceof URLSearchParams ? params : objectValuesFilter(params, [undefined, '']);
  };

  getUsers = (params: UsersSearchParams) => {
    return ApiService.GET<USERS_API.GetUsersResponse>(`/user/users`, this.#filterParams(params));
  };

  getById = (id: string | number) => {
    return ApiService.GET<ConedUser>(`/user/${id}`);
  };

  createUser = (userData: User) => {
    return ApiService.POST<{
      user: ConedUser;
    }>(`/user/role`, userData);
  };

  changeUserStatus = (id: string | number, approve: APPROVE) => {
    return ApiService.POST(`/user/approve`, { id, approve });
  };

  activateEmail = (id: string | number) => {
    return ApiService.POST(`/user/send-activate-email/${id}`, { should_reset_password: true });
  };

  deleteUser = (id: string | number) => {
    return ApiService.DELETE(`/user/${id}`);
  };

  getUserNotifications = (id: string | number) => {
    return ApiService.GET<{ notifications: NotificationsObject }>(`/user/${id}/notifications`);
  };

  updateUserNotifications = (id: string | number, notifications: APP_API.UpdateUserNotificationsParams = []) => {
    return ApiService.PUT_JSON(`/user/${id}/notifications`, {
      notifications,
    });
  };

  getSupervisors = (params: API.RequestBody) => {
    return ApiService.GET<{ supervisors: ConEdSupervisor[] }>(`/supervisors`, this.#filterParams(params));
  };

  getRequestors = (params: API.RequestBody) => {
    return ApiService.GET<{ results: ConEdRequestor[] }>(`/requestors`, this.#filterParams(params));
  };

  importFromExcel = (file: File) => {
    return ApiService.POST_FDATA(`/user/import-excel`, { excel: file });
  };

  uploadAvatar = (avatar: File) => {
    return ApiService.POST_FDATA<{ avatar_url: string; status_code: number }>(`/user/upload-avatar`, { avatar });
  };
}

export default new UsersAPI();

export namespace USERS_API {
  export type GetUsersResponse = {
    results: ConedUser[];
    has_more_pages: boolean;
    page: number;
    next_page_url: string | null;
    prev_page_url: string | null;
  };
}
