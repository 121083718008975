import { FC, memo } from 'react';

import { TableBody, TableRow, TableCell, Table, TableHead } from '@mui/material';

import Invoice from 'types/Invoices';

import { CopyIcon } from 'Utils/Icon';
import CopyToClipboardButton from 'components/CopyToClipboardButton/CopyToClipboardButton';

import { parsePaidTimesheetToCopyValue } from '../MarkTimesheetsAsPaid';
import { StyledInvoiceTotalHoursTable } from '../styled';

type Props = {
  paidTimesheets: Invoice.PaidTimesheet[];
};

const PaidTimesheetsTable: FC<Props> = ({ paidTimesheets }) => {
  return (
    <StyledInvoiceTotalHoursTable>
      <TableHead>
        <TableRow>
          <TableCell>Timesheet ID</TableCell>
          <TableCell>PO #</TableCell>
          <TableCell width={50}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {paidTimesheets.map(({ id, po_number }) => (
          <TableRow key={id}>
            <TableCell width={120}>{id}</TableCell>
            <TableCell>{po_number}</TableCell>
            <TableCell align="right">
              <CopyToClipboardButton
                snackbarMessage={'Timesheet copied to clipboard'}
                buttonProps={{
                  sx: {
                    color: 'text.primary',
                    borderRadius: '6px',
                    bgcolor: (theme) => theme.palette.secondary.light,
                  },
                  startIcon: <CopyIcon />,
                }}
                value={parsePaidTimesheetToCopyValue({ id, po_number })}
                tooltipText="Copy timesheet to clipboard."
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </StyledInvoiceTotalHoursTable>
  );
};

export default memo(PaidTimesheetsTable);
