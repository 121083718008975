import { APPROVE } from 'Constants/user';
import { User } from 'Models/APITypes';
import { UsersAPI } from 'Services/API';
import { createAppAsyncThunk } from 'Services/utils';

export const retrieveUsers = createAppAsyncThunk('users/retrieve', async (_, thunkAPI) => {
  const searchParams = thunkAPI.getState().users.searchParams;
  const response = await UsersAPI.getUsers(searchParams);
  return response;
});

export const getUserById = createAppAsyncThunk('users/getById', async (id: string | number) => {
  const response = await UsersAPI.getById(id);
  return response;
});

export const createUser = createAppAsyncThunk('users/create', async (user: User, thunkAPI) => {
  try {
    await UsersAPI.createUser(user);
    await thunkAPI.dispatch(retrieveUsers());
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateApprove = createAppAsyncThunk(
  'users/updateApprove',
  async ({ id, approve }: { id: string | number; approve: APPROVE }) => {
    await UsersAPI.changeUserStatus(id, approve);
    return { id, isApproved: approve };
  }
);

export const activateEmail = createAppAsyncThunk('users/activateEmail', async (id: string | number) => {
  const response = await UsersAPI.activateEmail(id);
  return response;
});

export const deleteUser = createAppAsyncThunk('users/delete', async (id: string | number) => {
  await UsersAPI.deleteUser(id);
  return id;
});
