import { FC } from 'react';

import { TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import Invoice from 'types/Invoices';

import { StyledInvoiceTotalHoursTable } from '../styled';

type Props = {
  headerTitle?: string;
  totalHoursPerInvoice: Invoice.TotalHoursPerInvoice[];
  totalHoursStats: Invoice.TotalHoursStats;
};

const InvoiceTotalHoursTable: FC<Props> = ({ headerTitle = '', totalHoursPerInvoice, totalHoursStats }) => {
  return (
    <StyledInvoiceTotalHoursTable>
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              bgcolor: (theme) => theme.palette.secondary.light,
            }}
          >
            {headerTitle ? `${headerTitle}:` : ''}
          </TableCell>
          <TableCell>Total</TableCell>
          <TableCell>Regular</TableCell>
          <TableCell>Overtime</TableCell>
          <TableCell>Total Billable</TableCell>
          <TableCell>Holiday</TableCell>
          <TableCell>Emergency</TableCell>
          <TableCell>Invoice ID</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {totalHoursPerInvoice.length > 1 &&
          totalHoursPerInvoice.map((row) => (
            <TableRow key={row.invoice_id}>
              <TableCell>Hours:</TableCell>
              <TableCell>{row.total_hours}</TableCell>
              <TableCell>{row.regular_hours}</TableCell>
              <TableCell>{row.overtime_hours}</TableCell>
              <TableCell>{row.total_billable_hours}</TableCell>
              <TableCell>{row.holiday_hours}</TableCell>
              <TableCell>{row.emergency_hours}</TableCell>
              <TableCell>{row.invoice_id}</TableCell>
            </TableRow>
          ))}
        <TableRow>
          <TableCell>Total Hours:</TableCell>
          <TableCell>{totalHoursStats.total_hours}</TableCell>
          <TableCell>{totalHoursStats.regular_hours}</TableCell>
          <TableCell>{totalHoursStats.overtime_hours}</TableCell>
          <TableCell>{totalHoursStats.total_billable_hours}</TableCell>
          <TableCell>{totalHoursStats.holiday_hours}</TableCell>
          <TableCell>{totalHoursStats.emergency_hours}</TableCell>
          <TableCell>{totalHoursPerInvoice.length === 1 ? totalHoursPerInvoice[0]?.invoice_id : ''}</TableCell>
        </TableRow>
      </TableBody>
    </StyledInvoiceTotalHoursTable>
  );
};

export default InvoiceTotalHoursTable;
