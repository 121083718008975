import { FC } from 'react';
import { useDispatch } from 'react-redux';

import BlockIcon from '@mui/icons-material/Block';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { Box, Typography } from '@mui/material';

import { AppThunkDispatch } from 'types';

import { APPROVE, USER_STATUSES_STRING } from 'Constants/user';
import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal';

type Props = {
  onClose: () => void;
  user: any;
  newStatus: APPROVE;
};

const UpdateUserStatusModal: FC<Props> = ({ onClose, user, newStatus }) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { inProcess: loading, promiseWrapper } = useProcessing();
  const retrieveUsers = () => dispatch(actions.UsersActions.retrieveUsers());
  const updateApprove = (id: number | string, approve: APPROVE) =>
    dispatch(actions.UsersActions.updateApprove({ id, approve }));

  const willBeApproved = newStatus === APPROVE.approved;
  const willBeRejected = newStatus === APPROVE.rejected;
  const userAlreadyApproved = user.status === USER_STATUSES_STRING.active;
  const userAlreadyRejected = user.status === USER_STATUSES_STRING.rejected;

  const iconByStatusMap = {
    [APPROVE.approved]: <HowToRegIcon sx={{ fontSize: 120 }} color="success" />,
    [APPROVE.rejected]: <BlockIcon sx={{ fontSize: 120 }} color="error" />,
  };

  const submitButtonTitleMap = {
    [APPROVE.approved]: 'Approve',
    [APPROVE.rejected]: 'Reject',
  };

  return (
    <AppPaperModal
      open
      onClose={onClose}
      modalId="delete-user"
      title={`${submitButtonTitleMap[newStatus]} user`}
      submitButton={{
        loading,
        title: submitButtonTitleMap[newStatus],
        onClick: () => {
          if ((willBeApproved && userAlreadyApproved) || (willBeRejected && userAlreadyRejected)) {
            showErrorMessage(`User already ${user.status}!`);
            onClose();
            return;
          }
          promiseWrapper(updateApprove(user.id, newStatus))
            .then(() => {
              showSuccessMessage(`User ${user.name} was ${willBeApproved ? 'approved' : 'rejected'}!`);
              retrieveUsers();
            })
            .catch(showErrorMessage)
            .finally(onClose);
        },
      }}
      cancelButton={{
        title: 'Cancel',
        onClick: onClose,
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={3}>
        {iconByStatusMap[newStatus]}
        <Typography variant="body1" gutterBottom>
          Are you sure you want to approve user: <strong>{user.name}</strong>?
        </Typography>
      </Box>
    </AppPaperModal>
  );
};

export default UpdateUserStatusModal;
