import { DetailedHTMLProps, forwardRef } from 'react';

import clsx from 'clsx';

import { CircularProgress, IconButton } from '@mui/material';

import { CloseIcon } from 'Utils/Icon';

import classes from './ImageButton.module.scss';

type Props = {
  url: string;
  loading?: boolean;
  onClosePress?: () => void;
  haveCloseIcon?: boolean;
  openImage?: (url: string) => void;
  selected?: boolean;
  withoutImage?: boolean;
  imageProps?: DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  noImagesStyle?: {
    container?: React.CSSProperties;
    content?: React.CSSProperties;
  };
} & Omit<DetailedHTMLProps<React.ImgHTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'loading'>;

const ImageButton = forwardRef<HTMLDivElement, Props>(
  (
    {
      url = '',
      loading = false,
      onClosePress = () => {},
      haveCloseIcon = false,
      openImage = () => {},
      selected = false,
      children,
      imageProps = {},
      withoutImage = false,
      noImagesStyle = {
        container: {},
        content: {},
      },
      ...rest
    },
    ref
  ) => {
    const onImageClick = () => openImage(url);

    if (withoutImage) {
      return (
        <div
          {...rest}
          key={url}
          ref={ref}
          className={clsx(classes.thumbWrapper, rest.className)}
          style={{ cursor: 'pointer', ...rest.style, ...noImagesStyle.container }}
        >
          <div className={classes.content} style={noImagesStyle.content}>
            {children}
          </div>
        </div>
      );
    }

    return (
      <div
        key={url}
        {...rest}
        ref={ref}
        className={clsx(classes.thumbWrapper, rest.className)}
        style={{ cursor: 'pointer', ...rest.style }}
      >
        {haveCloseIcon && (
          <IconButton className={classes.delete} size="small" color="primary" onClick={onClosePress}>
            <CloseIcon />
          </IconButton>
        )}
        {loading ? (
          <div className={classes.absolute_center}>
            <CircularProgress size={20} />
          </div>
        ) : null}

        <img
          style={selected ? { border: '2px solid white', ...imageProps?.style } : imageProps.style}
          alt=""
          onClick={onImageClick}
          {...imageProps}
          src={url}
        />

        <link href={url} as="image" rel="preload" />
        {children}
      </div>
    );
  }
);

ImageButton.displayName = 'ImageButton';

export default ImageButton;
