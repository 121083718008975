/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { toast, ToastOptions } from 'react-toastify';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Button, LinearProgress, Typography } from '@mui/material';

import { AppThunkDispatch } from 'types';
import { ConedUser, UsersSearchParams } from 'types/Common/User';

import { baseToastConfig } from 'Constants/app';
import ProfileModal from 'Containers/Profile/ProfileModal';
import { actions } from 'Services';
import { UsersAPI } from 'Services/API';
import { API_BASE_ENDPOINT } from 'Services/endpoint';
import { updateUsersSearchParams } from 'Services/users/slice';
import { showErrorMessage } from 'Utils/errorMessage';
import useAppMediaQuery from 'Utils/hooks/useAppMediaQuery';
import useProcessing from 'Utils/hooks/useProcessing';
import { useSearchParams } from 'Utils/hooks/useSearchParams';
import app_history from 'app_history';
import AppPagination from 'components/AppPagination/AppPagination';
import { usePopoverContext } from 'context/PopoversContext';
import { useAppSelector } from 'createStore';

import RolesFilter from './RolesFilter';
import RolesSortTable from './RolesSortTable';

const getPluralWord = (count = 0, word = '') => (count === 1 ? word : word + 's');

const RolesComponent: React.FC = () => {
  const { id: selectedUserID } = useParams<{ id: string }>();

  const dispatch = useDispatch<AppThunkDispatch>();
  const conedUsers = useAppSelector((state) => state.users.users);
  const nextPage = useAppSelector((state) => state.users.next_page_url);
  const prevPage = useAppSelector((state) => state.users.prev_page_url);
  const loading = useAppSelector((state) => state.users.processing);
  const searchOptions = useAppSelector((state) => state.users.searchParams);

  const updateSearchOptions = (options: UsersSearchParams) => {
    dispatch(updateUsersSearchParams(options));
  };

  const { openPopover, closeAll } = usePopoverContext();

  const { isDesktop } = useAppMediaQuery();

  const [filtersOpened, setFiltersOpened] = useState(false);

  const { inProcess: importing, promiseWrapper } = useProcessing();

  const retrieveUsers = () => dispatch(actions.UsersActions.retrieveUsers());

  const changeSortDirection = (sortByType: 'asc' | 'desc') =>
    updateSearchOptions({ order_by_type: sortByType === 'asc' });

  const changeSort = (name: keyof ConedUser) => {
    if (searchOptions.order_by !== name) {
      updateSearchOptions({ order_by: name, order_by_type: false });
      return;
    }
    if (!searchOptions.order_by_type) {
      updateSearchOptions({ order_by: name, order_by_type: true });
      return;
    }
    updateSearchOptions({ order_by: 'id', order_by_type: false });
  };

  const changeExcelFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      await importFromExcelFile(e.target.files[0]);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      e.target.value = null;
    }
  };

  const importFromExcelFile = async (file: File) => {
    try {
      const res = await promiseWrapper(UsersAPI.importFromExcel(file));
      const noCloseToast: ToastOptions = { ...baseToastConfig, autoClose: false };
      const { created = 0, updated = 0, failures = [] } = res;
      const errors = failures?.length || 0;
      if (created || updated) {
        const createdUsers = `${created} ${getPluralWord(created, 'user')} imported`;
        const updatedUsers = `${updated} ${getPluralWord(updated, 'user')} updated`;
        const errorUsers = `${errors} ${getPluralWord(errors, 'user')} throwed ${getPluralWord(errors, 'error')}`;
        toast.success(`${createdUsers}, ${updatedUsers}${errors ? ', ' + errorUsers : ''}`, noCloseToast);
        retrieveUsers();
      }
      if (errors) {
        failures?.forEach((failure) => {
          const row = failure?.row ? `Table Row: ${failure.row}` : '';
          const email = failure?.email ? `Email: ${failure.email}` : '';
          const error = failure?.error ? `Error: ${failure.error}` : '';
          setTimeout(
            () =>
              toast.error(
                <div key={email + row}>
                  {[row, email, error].map((text) => (text ? <p style={{ margin: 0 }}>{text}</p> : null))}
                </div>,
                noCloseToast
              ),
            10
          );
        });
      }
    } catch (error) {
      if (error.hasOwnProperty('error')) {
        toast.error(error.error, baseToastConfig);
      } else {
        const { errors = [] } = error;
        Object.keys(errors).forEach((key) => toast.error(errors[key][0], baseToastConfig));
      }
    }
  };

  const downloadImportTemplate = () => {
    const url = API_BASE_ENDPOINT?.replace('api/v1', 'ces_users_import_template.xlsx');
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = 'ces_users_import_template.xlsx';
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
    a.remove();
  };

  const openCreateUserModal = () => {
    openPopover('create-user-modal', <ProfileModal open onClose={closeAll} />);
  };

  useEffect(() => {
    retrieveUsers();
  }, [searchOptions]);

  return (
    <div className="roles-list-page">
      <Box className="page-header" display="flex">
        <Typography variant="h6">Manage Users Roles</Typography>

        {!isDesktop && (
          <Box display="flex">
            <Button
              variant={filtersOpened ? 'contained' : 'outlined'}
              onClick={() => setFiltersOpened((prev) => !prev)}
            >
              <FilterAltIcon />
            </Button>
          </Box>
        )}
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          gap={2}
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
            width: {
              xs: '100%',
              md: 'auto',
            },
          }}
          style={{ ...(filtersOpened ? { display: 'flex' } : {}) }}
        >
          <RolesFilter
            onChangeSort={changeSort}
            sort={{ direction: searchOptions.order_by_type ? 'asc' : 'desc', name: searchOptions.order_by }}
            onChangeSortDirection={changeSortDirection}
          />

          <Box
            display="flex"
            alignItems="center"
            gap={1}
            width="max-content"
            flexWrap={'wrap'}
            justifyContent={'center'}
          >
            <Button variant="contained" color="secondary" onClick={openCreateUserModal}>
              Create new
            </Button>

            <Link to={`/workers/create`}>
              <Button variant="contained" color="secondary">
                Add Worker
              </Button>
            </Link>

            <Button variant="contained" component="label">
              Import from Excel
              <input
                className="input-excel"
                accept=".xls,.xlsx"
                type="file"
                id="input-file"
                onChange={changeExcelFile}
              />
            </Button>

            <Button variant="contained" color="success" onClick={downloadImportTemplate}>
              Download Template
            </Button>
          </Box>
        </Box>
      </Box>
      <LinearProgress style={{ visibility: loading || importing ? 'visible' : 'hidden' }} />
      <Box
        display="flex"
        position={'relative'}
        flexDirection={'column'}
        paddingBottom={2}
        overflow={'auto'}
        className="container-fluid page-body"
        onClick={() => setFiltersOpened(false)}
      >
        <RolesSortTable
          users={conedUsers}
          onChangeSort={changeSort}
          sortBy={searchOptions.order_by}
          sortDirection={searchOptions.order_by_type ? 'asc' : 'desc'}
        />

        <AppPagination
          simplePagination
          perPageOptions={[5, 10, 25, 50, 75, 100]}
          wrapperProps={{ sx: { bgcolor: 'white', p: 2, borderTop: '1px solid #00000010' } }}
          count={conedUsers?.length || 0}
          perPage={searchOptions.per_page}
          page={searchOptions.page}
          onChangePerPage={(per_page) => updateSearchOptions({ per_page, page: 1 })}
          onChangePage={(page) => updateSearchOptions({ page })}
          nextPage={() => updateSearchOptions({ page: searchOptions.page + 1 })}
          prevPage={() => updateSearchOptions({ page: searchOptions.page - 1 })}
          nextPageDisabled={!nextPage || loading}
          prevPageDisabled={searchOptions.page === 1 || !prevPage || loading}
        />
      </Box>
      {Boolean(selectedUserID) && (
        <ProfileModal
          modalId="edit-profile-modal"
          open={Boolean(selectedUserID)}
          userID={selectedUserID}
          onClose={() => app_history.push('/roles')}
        />
      )}
    </div>
  );
};

export default RolesComponent;
