import React, { Component } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { toast } from 'react-toastify';

import { LoadingButton } from '@mui/lab';
import { Box, Collapse, Stack, Typography } from '@mui/material';

import Invoice from 'types/Invoices';

import { baseToastConfig } from 'Constants/app';
import { PO_Double_Validation } from 'Constants/validation';
import { actions } from 'Services';
import { InvoicesAPI } from 'Services/API';
import { Poppins } from 'Utils/Fonts';
import { CopyIcon, DownloadIcon, TimeIcon } from 'Utils/Icon';
import { showErrorMessage } from 'Utils/errorMessage';
import { showSuccessMessage } from 'Utils/successMessage';
import AppInputField from 'components/AppInputField/AppInputField';
import AppPaperModal from 'components/AppPaperModal';
import CopyToClipboardButton from 'components/CopyToClipboardButton/CopyToClipboardButton';
import { ReduxState } from 'createStore';

import InvoiceTotalHoursTable from './components/InvoiceTotalHoursTable';
import PaidTimesheetsTable from './components/PaidTimesheetsTable';

const timesheetIdsRegExp = /(^(\d+(\,|\s+|\,\s+))+\d+$)|(^\d+$)/;

const emptyTotalHoursStats: Invoice.TotalHoursStats = {
  emergency_hours: 0,
  holiday_hours: 0,
  overtime_hours: 0,
  regular_hours: 0,
  total_billable_hours: 0,
  total_hours: 0,
};

type Props = ReduxProps & {
  onClose: () => void;
  timesheet?: number;
  open: boolean;
  config_id: number;
  singleTimesheetMode?: boolean;
};

type State = {
  timesheets: string | number;
  po: string;
  processing: boolean;
  helperText: string;
  error: boolean;
  errorPo: string;
  showPo: boolean;
  getHoursProcessing: boolean;
  totalHoursStats: Invoice.TotalHoursStats;
  totalHoursPerInvoice: Invoice.TotalHoursPerInvoice[];
  paid_timesheets: Invoice.TimesheetTotalHours & {
    timesheets_po: Invoice.PaidTimesheet[];
  };
  unpaid_timesheets: Invoice.TimesheetTotalHours;
  downloadInvoicesInProgress: boolean;
};

export const parsePaidTimesheetToCopyValue = ({ id, po_number }: Invoice.PaidTimesheet): string => {
  return `Timesheet: ${id}, PO: ${po_number || '---'}`;
};

class MarkTimesheetsAsPaid extends Component<Props, State> {
  state: State = {
    timesheets: this.props.timesheet && this.props.timesheet !== -1 ? this.props.timesheet : '',
    po: '',
    processing: false,
    helperText: '',
    error: false,
    errorPo: 'PO # is required field',
    showPo: Boolean(this.props.timesheet),
    totalHoursStats: {
      emergency_hours: 0,
      holiday_hours: 0,
      overtime_hours: 0,
      regular_hours: 0,
      total_billable_hours: 0,
      total_hours: 0,
    },
    totalHoursPerInvoice: [],
    getHoursProcessing: false,
    paid_timesheets: {
      totals: emptyTotalHoursStats,
      hours: [],
      timesheet_ids: [],
      timesheets_po: [],
    },
    unpaid_timesheets: {
      totals: emptyTotalHoursStats,
      hours: [],
      timesheet_ids: [],
    },
    downloadInvoicesInProgress: false,
  };

  componentDidMount() {
    this.setState(
      {
        timesheets: this.props.timesheet ? this.props.timesheet : '',
        po: '',
        processing: false,
        helperText: '',
        error: false,
        showPo: this.props.timesheet ? true : false,
        errorPo: this.validatePo(''),
      },
      () => {
        if (this.state.timesheets !== '' && this.state.timesheets !== -1) this.getTotal();
      }
    );
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.timesheet !== -1 && this.props.timesheet !== prevProps.timesheet) {
      this.setState(
        {
          timesheets: this.props.timesheet ? this.props.timesheet : '',
          po: '',
          processing: false,
          helperText: '',
          error: false,
          showPo: this.props.timesheet ? true : false,
          errorPo: this.validatePo(''),
        },
        () => {
          if (this.state.timesheets !== '' && this.state.timesheets !== -1) this.getTotal();
        }
      );
    }
  };

  handleChangeIds = (event) => {
    const timesheets = event.target.value;
    this.setState({ timesheets, showPo: false, po: '', errorPo: this.validatePo('') });
    if (timesheetIdsRegExp.test(timesheets.trim())) {
      this.setState({ helperText: '', error: false });
    } else {
      this.setState({
        helperText: 'You should enter list of timesheet ids separated by comma and/or space only',
        error: true,
      });
    }
    if (!event.target.value) {
      this.setState({ errorPo: this.validatePo('') });
    }
  };

  enterPressed = (event: React.KeyboardEvent) => {
    const { error, timesheets } = this.state;
    if (event.key === 'Enter' && !error && timesheets.toString().trim() !== '') {
      this.getTotal();
    }
  };

  handleChangePo = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    let { errorPo } = this.state;
    errorPo = this.validatePo(value);
    this.setState({ po: value, errorPo });
  };

  validatePo = (poNumber: string = ''): string => {
    try {
      PO_Double_Validation.min(1, 'PO # is required field').required('PO # is required field').validateSync(poNumber);
      return '';
    } catch (error) {
      return error.message;
    }
  };

  confirm = async () => {
    await this.props
      .markTimesheets(this.props.config_id, {
        timesheetIds: this.state.timesheets.toString().trim(),
        po: this.state.po,
      })
      .then(({ message, status_code }) => {
        if (status_code === 210) {
          toast.warn(message, { ...baseToastConfig, autoClose: false });
        } else {
          showSuccessMessage(message || 'Timesheets marked as paid');
        }
        this.setState({
          timesheets: '',
          po: '',
          processing: false,
          helperText: '',
          error: false,
          showPo: false,
        });
        this.props.onClose();
      })
      .catch(showErrorMessage);
  };

  getTotal = async () => {
    this.setState({ getHoursProcessing: true });
    try {
      const response = await InvoicesAPI.getTimesheetsTotalHours({ timesheetIds: this.state.timesheets });
      this.setState({
        paid_timesheets: response.result.paid_timesheets,
        unpaid_timesheets: response.result.unpaid_timesheets,
        showPo: true,
        po: this.state.po || response.result.po_number,
        errorPo: this.validatePo(this.state.po || response.result.po_number),
      });
    } catch (error) {
      showErrorMessage(error);
      this.setState({ showPo: false });
    } finally {
      this.setState({ getHoursProcessing: false });
    }
  };

  downloadInvoiceTimesheets = async () => {
    this.setState({ downloadInvoicesInProgress: true });
    try {
      await InvoicesAPI.downloadInvoiceTimesheets(this.state.paid_timesheets.timesheet_ids);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      this.setState({ downloadInvoicesInProgress: false });
    }
  };

  render() {
    const { singleTimesheetMode, mark_timesheets_processing } = this.props;
    const { getHoursProcessing } = this.state;
    const submitEnabled = this.state.timesheets && !this.state.error && this.state.showPo && !this.state.errorPo;

    return (
      <AppPaperModal
        modalId="pay-timesheets-modal"
        open={this.props.open}
        onClose={this.props.onClose}
        title="Pay Timesheets"
        contentStyle={{ minWidth: 600 }}
        submitButton={{
          title: 'Pay',
          onClick: this.confirm,
          loading: mark_timesheets_processing,
          disabled: !submitEnabled,
        }}
      >
        <Stack>
          <Stack gap={'12px'}>
            <Box display="flex" alignItems="flex-end" gap={'10px'}>
              <AppInputField
                fullWidth
                name="ids"
                label="Timesheets ID"
                id="outlined-size-normal"
                size="small"
                value={this.state.timesheets}
                onKeyDown={this.enterPressed}
                onChange={this.handleChangeIds}
                placeholder="Enter timesheet ids"
                multiline
                maxRows={3}
                error={this.state.error}
                required
                disabled={singleTimesheetMode}
              />

              <LoadingButton
                variant="outlined"
                onClick={this.getTotal}
                loading={getHoursProcessing}
                disabled={!this.state.timesheets || this.state.error}
                startIcon={<TimeIcon />}
                loadingPosition="start"
                sx={{ flexShrink: 0, height: 41 }}
              >
                See Total Hours
              </LoadingButton>
            </Box>

            <Typography variant="caption" color={this.state.error ? 'error' : 'textSecondary'}>
              {this.state.helperText ||
                'Enter timesheet ids separated by comma and/or space and press Enter or button below'}
            </Typography>
          </Stack>

          <Collapse in={this.state.showPo} orientation="horizontal">
            <Collapse in={this.state.showPo}>
              <Stack gap={3} pt={3}>
                <Stack gap="20px">
                  <InvoiceTotalHoursTable
                    headerTitle="Unpaid Hours"
                    totalHoursPerInvoice={this.state.unpaid_timesheets?.hours || []}
                    totalHoursStats={this.state.unpaid_timesheets?.totals}
                  />
                  {Boolean(this.state.paid_timesheets?.timesheets_po.length) && (
                    <Stack gap="18px">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          borderRadius: '6px',
                          padding: '5px 6px 5px 10px',
                          bgcolor: ({ palette }) => palette.secondary.light,
                        }}
                      >
                        <Typography fontFamily={Poppins[600]} fontSize={12}>
                          Already Paid Timesheets
                        </Typography>
                        <Box display="flex" alignItems="center" gap={2}>
                          <LoadingButton
                            startIcon={<DownloadIcon />}
                            onClick={this.downloadInvoiceTimesheets}
                            loading={this.state.downloadInvoicesInProgress}
                            loadingPosition="start"
                            disabled={!this.state.paid_timesheets?.timesheet_ids?.length}
                            sx={{ bgcolor: 'white' }}
                          >
                            Download
                          </LoadingButton>
                          {this.state.paid_timesheets?.timesheets_po.length > 1 && (
                            <CopyToClipboardButton
                              buttonProps={{
                                color: 'primary',
                                startIcon: <CopyIcon fontSize="small" />,
                                sx: { bgcolor: 'white' },
                              }}
                              snackbarMessage="All timesheets copied to clipboard"
                              tooltipText="Copy all timesheets to clipboard."
                              value={
                                this.state.paid_timesheets?.timesheets_po
                                  .map(parsePaidTimesheetToCopyValue)
                                  .join('; \n') + ';'
                              }
                            >
                              Copy All
                            </CopyToClipboardButton>
                          )}
                        </Box>
                      </Box>
                      <PaidTimesheetsTable paidTimesheets={this.state.paid_timesheets.timesheets_po} />
                      <InvoiceTotalHoursTable
                        headerTitle="Paid Hours"
                        totalHoursPerInvoice={this.state.paid_timesheets.hours || []}
                        totalHoursStats={this.state.paid_timesheets.totals}
                      />
                    </Stack>
                  )}
                </Stack>
                <AppInputField
                  fullWidth
                  name="po"
                  label="PO"
                  id="outlined-size-normal"
                  value={this.state.po}
                  onChange={this.handleChangePo}
                  helperText={this.state.errorPo}
                  error={!!this.state.errorPo}
                />
              </Stack>
            </Collapse>
          </Collapse>
        </Stack>
      </AppPaperModal>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    mark_timesheets_processing: state.invoices.mark_timesheets_processing,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    markTimesheets: (config_id: string | number, data) =>
      dispatch(actions.InvoicesActions.markTimesheets(config_id, data)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(MarkTimesheetsAsPaid);
