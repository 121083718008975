import { FC } from 'react';

import { Button, ButtonProps } from '@mui/material';

import * as CEIcon from 'Utils/Icon';

type Props = ButtonProps & {
  verified: boolean;
};

const VerifyJobsButton: FC<Props> = ({ verified = false, children, ...props }) => {
  return (
    <Button
      variant="contained"
      startIcon={
        !verified ? <CEIcon.ShieldTickIcon width={20} height={20} /> : <CEIcon.UnverifyIcon width={20} height={20} />
      }
      {...props}
    >
      {children}
    </Button>
  );
};

export default VerifyJobsButton;
