import { FC } from 'react';

import { DisabledByDefaultRounded } from '@mui/icons-material';
import { Checkbox, styled, Tooltip } from '@mui/material';

const ThemedDisabledCheckbox = styled(Checkbox)(({ theme, checked }) => ({
  '&.Mui-disabled': {
    color: theme.palette[checked ? 'success' : 'error'].main,
  },
}));

type Props = {
  verified: boolean;
  verifierName: string;
  hideEmpty?: boolean;
};

const VerificationCheckboxWithTooltip: FC<Props> = ({ verified, verifierName, hideEmpty = false }) => {
  if (!verifierName && hideEmpty) return null;

  return (
    <Tooltip
      disableInteractive
      placement="bottom"
      title={verifierName ? `${verified ? 'Verified' : 'Unverified'} by ${verifierName}` : ''}
    >
      <span>
        {verifierName ? (
          <ThemedDisabledCheckbox icon={<DisabledByDefaultRounded />} checked={!!verified} disabled />
        ) : (
          <Checkbox disabled />
        )}
      </span>
    </Tooltip>
  );
};

export default VerificationCheckboxWithTooltip;
