// import reducers
import { schedulingReducer } from 'Containers/Scheduling/slice';

import AppReducer from './app/reducer';
import BillingReducer from './billing/reducer';
import CommentsReducer from './comments/reducer';
import GrouppedTimesheetsReducer from './grouppedTimesheets/reducer';
import InvoicesReducer from './invoices/reducer';
import JobsReducer from './jobs/reducer';
import MapReducer from './map/reducer';
import NotesReducer from './notes/reducer';
import NotificationsReducer from './notifications/reducer';
import ReportsReducer from './reports/reducer';
import SubcontractorsReducer from './subcontractors/reducer';
import TimesheetsReducer from './timesheets/reducer';
import UserPermissions from './userPermissions/reducer';
import { UsersReducer } from './users/slice';
import WorkersReducer from './workers/reducer';
import ZonesReducer from './zones/reducer';

const reducers = {
  app: AppReducer,
  userPermissions: UserPermissions,
  jobs: JobsReducer,
  workers: WorkersReducer,
  timesheets: TimesheetsReducer,
  grouppedTimesheets: GrouppedTimesheetsReducer,
  subcontractors: SubcontractorsReducer,
  zones: ZonesReducer,
  users: UsersReducer,
  invoices: InvoicesReducer,
  billing: BillingReducer,
  notifications: NotificationsReducer,
  reports: ReportsReducer,
  notes: NotesReducer,
  comments: CommentsReducer,
  map: MapReducer,
  scheduling: schedulingReducer,
};

export default reducers;
