import { BaseLocation } from 'types/Common/LocationData';
import { ConedUser, Role } from 'types/Common/User';

import { TimeLimitation } from 'Constants/companies';
import { TwoFactorCodeChannel } from 'Containers/Auth/components/AuthForm';
import { ProfileFormValues } from 'Containers/Profile/components/ProfileForm';
import ApiService, { API } from 'Services/HttpService';

export const enum AUTH_LOGIN_RESPONSE_TYPE {
  two_factor_auth_enabled = 'two_factor_auth_enabled',
  bearer_token = 'bearer_token',
}

class AppAPI {
  getPermissions = () => {
    return ApiService.GET(`/permissions`);
  };

  getRoles = () => {
    return ApiService.GET<Role[]>(`/roles`);
  };

  getDepartments = (params: API.RequestBody) => {
    return ApiService.GET(`/departments`, params);
  };

  updateDepartment = (id: number, data: APP_API.UpdateDepartmentParams): Promise<Response> => {
    return ApiService.PUT_JSON(`/departments/${id}`, data);
  };

  getDepartmentGroups = (load_departments = true) => {
    return ApiService.GET(`/department-groups`, { load_departments });
  };

  updateDepartmentGroup = (id: number, data: APP_API.UpdateDepartmentGroupParams): Promise<Response> => {
    return ApiService.PUT_JSON(`/department-groups/${id}`, data);
  };

  getZones = () => {
    return ApiService.GET(`/zones`);
  };

  login = (params: APP_API.LoginParams): Promise<APP_API.LoginResponse> => {
    return ApiService.POST(`/auth/login`, { ...params, authType: 'admin' });
  };

  twoFactorAuthLogin = (params: APP_API.TwoFactorAuthLoginParams) => {
    return ApiService.POST(`/user/two-factor-login`, params);
  };

  twoFactorAuthSendCode = (params: APP_API.TwoFactorAuthSendCodeParams) => {
    return ApiService.POST(`/user/send-two-factor-code`, params);
  };

  signup = (user: API.RequestBody) => {
    return ApiService.POST(`/user/signup`, user);
  };

  getFileTypes = () => {
    return ApiService.GET(`/file-types`);
  };

  getCurrentUser = () => {
    return ApiService.GET<ConedUser>(`/user`);
  };

  // Possibly not using
  activateAccount = (token: string, password: string) => {
    return ApiService.POST(`/user/activate-with-password`, { token, password });
  };

  updateCurrentUser = (data: any) => {
    return ApiService.PUT(`/user`, data);
  };

  updateCurrentUserPassword = (data: { currentPassword: string; password: string }) => {
    return ApiService.PUT(`/user/password-change`, data);
  };

  getCurrentUserNotifications = (): Promise<{
    notifications: {
      [key: string]: APP_API.NotificationItem[];
    };
  }> => {
    return ApiService.GET(`/user/notifications`);
  };

  updateCurrentUserNotifications = (notifications: APP_API.UpdateUserNotificationsParams) => {
    return ApiService.PUT_JSON(`/user/notifications`, { notifications });
  };

  passwordForgot = (email: string) => {
    return ApiService.POST(`/user/password-forgot`, { email });
  };

  passwordRestore = ({ token = '', password = '' }) => {
    return ApiService.POST(`/user/password-restore/${token}`, { password });
  };

  getPlacesByAddress = (address: string) => {
    return ApiService.GET<{ places: APP_API.PlaceItem[] }>(`/places`, { address, lat: 40.73061, lon: -73.935242 });
  };

  getLocationByFacilityId = (facility_id: string) => {
    return ApiService.GET<{ location: APP_API.FacilityLocationItem }>(`/facilities/${facility_id}`);
  };

  getPlaceInfoById = (place_id: string) => {
    return ApiService.GET<{ place: { lat: number; lon: number; address: string } }>(`/places/${place_id}`);
  };

  sendFCMToken = (fcm_token: string = '') => {
    return ApiService.POST_JSON(`/user/fcm`, { fcm_token });
  };

  saveRotatedImage = async (url: string, degree: number) => {
    const response = await ApiService.POST_JSON<{ url: string }>(`/files/rotate-image`, { url, degree });
    return response.url;
  };

  restoreFileByID = (file_id: number) => {
    return ApiService.POST(`/files/${file_id}/restore`);
  };
}

export default new AppAPI();

export namespace APP_API {
  export type PlaceItem = {
    place_id: string;
    address: string;
    lat: number;
    lon: number;
  };

  export type FacilityLocationItem = Omit<BaseLocation, 'lat' | 'lng'> & {
    lat: string;
    lng: string;
  };

  export type UpdateDepartmentGroupParams = {
    skip_invoicing_to_be_billed?: boolean;
  };

  export type UpdateUserNotificationsParams = {
    id: number;
    title: string;
    notify_email: 0 | 1;
    notify_web_push: 0 | 1;
  }[];

  export type UpdateDepartmentParams = UpdateDepartmentGroupParams & {
    job_time_limitation?: TimeLimitation;
    ot_break?: boolean;
  };

  export type NotificationItem = {
    id: number;
    title: string;
    notify_email: 0 | 1;
    notify_web_push: 0 | 1;
  };

  export type LoginParams = {
    email: string;
    password: string;
  };

  export type LoginResponse =
    | { response_type: AUTH_LOGIN_RESPONSE_TYPE.two_factor_auth_enabled }
    | { response_type: AUTH_LOGIN_RESPONSE_TYPE.bearer_token; token: string };

  export type TwoFactorAuthLoginParams = APP_API.LoginParams & {
    code: string;
    channel: TwoFactorCodeChannel;
  };

  export type TwoFactorAuthSendCodeParams = APP_API.LoginParams & {
    channel: TwoFactorCodeChannel;
  };
}
