import { FC, useMemo, useState } from 'react';

import { Box, Chip, Stack, styled, Typography } from '@mui/material';
import { plPL } from '@mui/x-date-pickers';

import { Job } from 'types/Job';

import { actions } from 'Services';
import { UpdateJobVerificationRequest } from 'Services/API/JobsAPI.service';
import { Poppins } from 'Utils/Fonts';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal';
import { useAppDispatch } from 'createStore';

type Props = {
  open: boolean;
  onClose: () => void;
  selectedJobs: Job[];
  onSuccessfulVerification?: () => void;
};

const SelectedJobChip = styled(Chip)(
  ({
    theme: {
      palette: { primary },
    },
  }) => ({
    borderRadius: 9999,
    lineHeight: '18px',
    fontSize: 12,
    color: '#667085',
    padding: '4px 8px',
    '&.Mui-disabled': {
      opacity: 1,
      '& .MuiChip-deleteIcon': {
        display: 'none',
      },
    },

    '& .MuiChip-label': {
      padding: 0,
    },

    '&.MuiChip-root': {
      display: 'flex',
      gap: 4,
      '& .MuiChip-deleteIcon': {
        color: primary.main,
        '&:hover': { color: primary.main, opacity: 0.6 },
        margin: 0,
      },
    },
  })
);

const VerifyJobsModal: FC<Props> = ({
  open,
  onClose,
  selectedJobs: initialSelectedJobs,
  onSuccessfulVerification = () => {},
}) => {
  const dispatch = useAppDispatch();
  const [selectedJobs, setSelectedJobs] = useState(
    initialSelectedJobs.some(({ verified }) => !verified)
      ? initialSelectedJobs.filter(({ verified }) => !verified)
      : initialSelectedJobs
  );

  const isInitialMultipleVerification = initialSelectedJobs.length > 1;
  const isMultipleJobsVerification = selectedJobs.length > 1;
  const areAllSelectedJobsVerified = selectedJobs.every(({ verified }) => Boolean(verified));
  const updateJobVerification = (data: UpdateJobVerificationRequest) =>
    dispatch(actions.JobsActions.updateJobVerification(data));

  const verificationLabel = areAllSelectedJobsVerified ? 'Unverify' : 'Verify';
  const modalTitle = isMultipleJobsVerification ? `${verificationLabel} Jobs` : `${verificationLabel} Job`;

  const submitButtonTitle = isMultipleJobsVerification ? `${verificationLabel} Selected` : verificationLabel;

  const { inProcess, promiseWrapper } = useProcessing();

  const removeJobSelection = (job: Job) => setSelectedJobs(selectedJobs.filter(({ id }) => id !== job.id));

  const onSubmit = async () => {
    try {
      const job_ids = selectedJobs.map(({ id }) => id);
      await promiseWrapper(updateJobVerification({ job_ids, verified: !areAllSelectedJobsVerified }));
      onSuccessfulVerification();
      showSuccessMessage(`Verification status has been updated`);
      onClose();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const renderModalContent = useMemo(() => {
    if (areAllSelectedJobsVerified) {
      return (
        <Typography>
          You can set {isMultipleJobsVerification ? 'jobs' : 'job'} as unverified if you found some mistakes
        </Typography>
      );
    }

    return (
      <>
        <Typography>
          If everything is ok, click the{' '}
          <Typography component="span" color="primary">
            "{submitButtonTitle}"{' '}
          </Typography>
          button when it appears.
        </Typography>
        <Typography>
          Mark {isMultipleJobsVerification ? 'jobs' : 'job'} as verified if you are sure that everything is ok
        </Typography>
      </>
    );
  }, [areAllSelectedJobsVerified, isMultipleJobsVerification, submitButtonTitle]);

  return (
    <AppPaperModal
      modalId="verify-jobs-modal"
      open={open}
      containerStyle={{ width: '100%', maxWidth: 524 }}
      onClose={onClose}
      submitButton={{
        title: submitButtonTitle,
        timer: areAllSelectedJobsVerified ? 0 : 3,
        loading: inProcess,
        onClick: onSubmit,
      }}
      title={modalTitle}
    >
      <Stack gap={'13px'}>
        <Stack
          gap={1}
          sx={{
            '& .MuiTypography-root': {
              fontFamily: Poppins[500],
              fontSize: 13,
            },
          }}
        >
          {renderModalContent}
        </Stack>

        {isInitialMultipleVerification && (
          <>
            <Typography fontSize={13} fontFamily={Poppins[600]}>
              Selected Jobs:
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {selectedJobs.map((job) => (
                <SelectedJobChip
                  label={`#${job.id}`}
                  key={job.id}
                  onDelete={() => removeJobSelection(job)}
                  disabled={!isMultipleJobsVerification}
                />
              ))}
            </Box>

            {!areAllSelectedJobsVerified && (
              <>
                <Typography fontSize={13} fontFamily={Poppins[600]}>
                  Already verified jobs will be skipped:
                </Typography>

                <Box display="flex" flexWrap="wrap" gap={1}>
                  {initialSelectedJobs
                    .filter(({ verified }) => verified)
                    .map((job) => (
                      <SelectedJobChip label={`#${job.id}`} key={job.id} disabled />
                    ))}
                </Box>
              </>
            )}
          </>
        )}
      </Stack>
    </AppPaperModal>
  );
};

export default VerifyJobsModal;
