import { Table, styled } from '@mui/material';

import { Poppins } from 'Utils/Fonts';

export const StyledInvoiceTotalHoursTable = styled(Table)({
  '& .MuiTableCell-root': {
    fontFamily: Poppins[500],
    fontSize: '12px',
    borderBottom: '1px solid #E0E0E0',
    padding: '20px 10px',
    lineHeight: '18px',
  },

  '& .MuiTableCell-head': {
    fontFamily: Poppins[600],
    padding: '10px',
  },
});
