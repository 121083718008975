import React from 'react';
import { connect } from 'react-redux';

import { withFormik } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button } from '@mui/material';

import { Department, DepartmentGroup } from 'types/Common/Companies';

import { departmentsGroupID } from 'Constants/departments';
import { JobType, JobTypeHelper } from 'Constants/job';
import WarningDialog from 'Containers/Components/WarnindDialog/WarningDialog';
import { actions } from 'Services';
import { JobsAPI } from 'Services/API';
import { JOB_CREATE_REQUEST } from 'Services/jobs/actionTypes';
import { FORMATS } from 'Utils/Date';
import FilesUpload from 'Utils/FilesUpload';
import UserPermissions from 'Utils/PermissionsHelper';
import confirmAlert from 'Utils/confirmAlert';
import { showErrorMessage } from 'Utils/errorMessage';
import app_history from 'app_history';
import { ReduxState } from 'createStore';

import './JobCreate.scss';
import { job as jobSchema, requestTimeValidation } from './JobCreateValidation';
import { JobCreateValidation } from './JobCreateValidation';
import JobFormComponent, { JobCreateEdit } from './JobFormComponent';
import { JobsLeftBlock } from './components/JobsLeftBlock/JobsLeftBlock';

const regExpStructure = /\W|_/gi;
// eslint-disable-next-line
const MAX_DUPLICATE_INTERVAL = 30;
export const default_location = {
  lat: 0,
  lng: 0,
  address: null,
  structure: '',
  start_time: '00:00',
  finish_time: '23:59',
  max_workers: 1,
};
interface DefaultLocation {
  lat: number;
  lng: number;
  address: any;
  structure: string;
  start_time: string;
  finish_time: string;
  max_workers: number;
}
const initialSearch = {
  jobType: [],
  jobStatus: [0, 1],
  schedules_needed: null,
  late_workers: null,
  unassigned: null,
  requestDate: {
    from: null,
    to: null,
  },
  location: null,
  municipality: null,
  requestor: null,
  supervisor: null,
  department: null,
  worker: null,
  search: null,
  radius: '',
  radiusType: null,
  structure: '',
  purchase: '',
  worker_number: '',
  page: 0,
};

const default_job = {
  index: 0,
  ccUser: null,
  changesLog: null,
  comment: '',
  department: null,
  endTime: null,
  feeder: '',
  jobStatus: '',
  jobType: 0,
  location: null,
  locations: [default_location],
  municipality: null,
  po: '',
  poet: '',
  projectId: null,
  projectPo: null,
  projectTitle: null,
  receipt: '',
  requestDate: null,
  requestTime: null,
  requestor: null,
  requisition: '',
  section: null,
  structure: null,
  supervisor: null,
  uid: null,
  workers: [],
  wr: '',
  maxWorkers: null,
  confirmedDuplicate: undefined,
};

class JobCreateComponent extends React.Component<any, any> {
  existsJobs: any = [];
  removedExistsJobs: Array<any> = [];
  formSubmit: any = null;
  myRef: any;

  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      jobs: [],
      selectedIndex: 0,
      duplicates: [],
      duplicatesChecked: false,
      showDuplicateAlert: false,
      existJobsChecked: false,
      //myRef: {}
    };
    this.myRef = {}; // Create a ref object
  }

  componentDidMount = () => {
    const { id } = this.props.match.params;
    const currentRoundedUpDate = moment().startOf('minute').format(FORMATS.jobCreateDate);
    if (id) {
      JobsAPI.getJob({ jobId: id }).then((job) => {
        const oldJobType = new JobTypeHelper(job.jobType);
        const department = this.props.departments.find(({ id }) => id === job.department) || {};
        const newJob: JobCreateEdit & { index: number } = {
          ...job,
          requestTime: currentRoundedUpDate,
          endTime: job.endTime ? moment(job.endTime).format(FORMATS.jobCreateDate) : job.endTime,
          department: {
            ...department,
            id: job.department,
            name: job.departmentName,
          },
          requestor: {
            id: job.requestor,
            name: job.requestorName,
            ...job.requestorObj,
          },
          supervisor:
            job.supervisor && job.supervisorObj
              ? {
                  id: job.supervisor,
                  name: job.supervisorName,
                  ...job.supervisorObj,
                }
              : null,
          ccUser: job.ccUser.map((cc_user) => ({ label: cc_user.email, value: cc_user })),
          index: 0,
          jobImages: [],
          legacy_id: null,
          coned_imported: false,
          po: '',
          jobStatus: 0,
          requisition: '',
          status: 'new',
          preferredWorkerIds: job.preferredWorkers.map(({ id }) => id),
          exist_approved_timesheets: false,
          jobType: oldJobType.isSignage ? JobType.Signage : job.jobType,
          comment: '',
          locations: job.locations.map((location) => ({
            ...location,
            images: [],
            workersCount: 0,
            note: '',
          })),
          jobPdfs: [],
          l2_org: null,
          l2_code: null,
        };
        delete newJob.preferredWorkers;
        this.setState({ jobs: [newJob] });
        this.props.setFieldValue('jobs', [newJob], false);
      });
    } else {
      default_job.requestTime = currentRoundedUpDate;
      this.setState(
        {
          jobs: [default_job],
        },
        () => {
          this.props.setFieldValue('jobs', this.state.jobs, false);
        }
      );
    }
  };

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>): void {
    if (prevState.jobs.length !== this.state.jobs.length) {
      const jobsWithUpdatedIndexes = this.state.jobs.map((job, index) => ({ ...job, index }));
      this.setState({ jobs: jobsWithUpdatedIndexes });
    }
  }

  componentWillUnmount(): void {
    // default_job.requestTime = moment().format('YYYY-MM-DDTHH:mm:ss');
    default_job.locations = [default_location];
    this.setState({
      jobs: [default_job],
    });
    this.myRef = {};
  }

  // componentDidUpdate(prevState): void {
  //   if(prevState.jobs !== this.state.jobs)
  //    this.myRef = {};
  // }

  addNewJob = (job = null) => {
    const newJob = job
      ? { ...job, index: this.state.jobs.length, requestTime: null, endTime: null }
      : { ...default_job, index: this.state.jobs.length };

    this.setState({ jobs: [...this.state.jobs, newJob], duplicatesChecked: false, existJobsChecked: false });
  };

  removeJob(ind) {
    const arr = [...this.state.jobs];
    if (ind < arr.length && arr.length > 1) {
      arr.splice(ind, 1);
      // this.setState({ jobs: arr, selectedIndex: arr.length > 0 ? arr.length - 1 : 0 }, () => {delete this.myRef[ind]; this.handleClickScroll(0)});
      this.setState({
        jobs: arr,
        selectedIndex: arr.length > 0 ? arr.length - 1 : 0,
        existJobsChecked: false,
        duplicatesChecked: false,
      });
    }
    this.props.resetForm({ jobs: arr });
    this.props.setTouched({}, false);
    this.props.validateForm();
  }

  onJobFormChange = (idx, name, value) => {
    const updated_jobs = this.state.jobs.map((job, index) => {
      if (idx !== index) return job;

      let updatedJob = { ...job, [name]: value };

      if (name === 'jobType') {
        const newJobType = value;
        const prevJobType = job.jobType;
        const maxWorkers = newJobType === JobType.Signage ? 1 : prevJobType === JobType.Signage ? null : job.maxWorkers;
        const is_standby = newJobType !== JobType.Flagging ? 0 : job.is_standby;

        updatedJob = {
          ...updatedJob,
          maxWorkers,
          is_standby,
        };
      }

      return updatedJob;
    });

    this.setState({
      jobs: updated_jobs,
      existJobsChecked: false,
      duplicatesChecked: false,
    });
    this.props.setFieldValue('jobs', updated_jobs, false);
    if (this.props.isValidating === true) {
      this.props.validateField(name);
    }
  };

  compareLocationsArrays = (target: DefaultLocation[], compare: DefaultLocation[]) => {
    return compare.length > 0
      ? target.some(
          (location) =>
            compare.some(
              (compLctn) =>
                location.address &&
                compLctn.address &&
                compLctn.address.toLowerCase() === location.address.toLowerCase()
            ) ||
            compare.some(
              (compLctn) =>
                compLctn.structure &&
                location.structure &&
                compLctn.structure.replace(regExpStructure, '').toLowerCase() ===
                  location.structure.replace(regExpStructure, '').toLowerCase()
            )
        )
      : false;
  };

  checkTimeDiff = (firstTime, secondTime, timeInterval = 30, timeUnit: moment.unitOfTime.Diff = 'minutes') => {
    const timeDiff = moment(firstTime).diff(moment(secondTime), timeUnit);
    return timeDiff > 0 - timeInterval && timeDiff < timeInterval;
  };

  checkDuplicateJobs = () => {
    const duplicates = [];
    const unique = [];
    const jobs = this.state.jobs.map((job) => {
      const timeInterval = job.jobType === JobType.Parking ? 60 : 30;
      const timeUnit: moment.unitOfTime.Diff = job.jobType === JobType.Parking ? 'days' : 'minutes';

      const duplicateJob = unique.find(
        (uniqueJob) =>
          this.checkTimeDiff(uniqueJob.requestTime, job.requestTime, timeInterval, timeUnit) &&
          this.compareLocationsArrays(job.locations, uniqueJob.locations) &&
          uniqueJob.department &&
          job.department &&
          uniqueJob.department.id === job.department.id
      );

      if (duplicateJob) {
        const adress = job.locations[0].address;
        const uniqueAdress = duplicateJob.locations[0].address;
        duplicates.push({
          index: job.index,
          uniqueIndex: duplicateJob.index,
          uniqueAdress,
          uniqueStructure: this.getStructuresString(duplicateJob.locations, '#'),
          adress,
          structure: this.getStructuresString(job.locations, '#'),
          adressDuplicate: adress === uniqueAdress,
          structureDuplicate: duplicateJob.locations.some((loc) =>
            job.locations.some(
              (jobLoc) =>
                jobLoc.structure &&
                loc.structure &&
                jobLoc.structure.replace(regExpStructure, '').toLowerCase() ===
                  loc.structure.replace(regExpStructure, '').toLowerCase()
            )
          ),
        });
        return { ...job, confirmedDuplicate: true };
      } else {
        unique.push(job);
        return { ...job, confirmedDuplicate: undefined };
      }
    });

    if (duplicates.length) {
      this.setState({ duplicatesChecked: false, showDuplicateAlert: true, duplicates: duplicates, jobs });
      return;
    }
    this.setState({ duplicates: [], duplicatesChecked: true, jobs });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ processing: true });
    this.props.setFieldValue('jobs', this.state.jobs, true);

    if (!this.state.duplicatesChecked) {
      if (this.state.jobs.length > 1) {
        this.checkDuplicateJobs();
        this.setState({ processing: false });
        return;
      }
      this.setState({ duplicatesChecked: true, processing: false });
      return;
    }
    if (this.props?.errors?.jobs?.length > 0) {
      this.setState({ processing: false });
      return;
    }

    const now = moment().subtract(2, 'hour').toDate(),
      requestTime = new Date(this.state.jobs[0].requestTime),
      canCreatePastDateJob = UserPermissions.has.create_job_with_past_date;

    if (now > requestTime) {
      if (canCreatePastDateJob) {
        this.saveJobs();
        return;
      }
      this.setState({ processing: false });
      showErrorMessage("You can't create job with past date");
      return;
    }

    this.saveJobs();
    //this.props.handleSubmit(event, this.state.jobs);
  };

  confirmDuplicates = () => {
    this.setState({ showDuplicateAlert: false, duplicatesChecked: true });
  };

  removeDuplicates = () => {
    this.state.duplicates.forEach((el) => {
      this.removeJob(el.index);
    });
    this.setState({ duplicates: [], showDuplicateAlert: false, duplicatesChecked: true });
  };

  handleClickScroll = (id) => {
    this.myRef[id].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  checkExistsJob = async () => {
    try {
      this.setState({ processing: true });
      const paramsArray = this.state.jobs.map(({ department, jobType, locations, maxWorkers, requestTime }) => ({
        department: department.id,
        jobType,
        locations,
        maxWorkers,
        requestTime,
      }));
      const jobFromApi = await Promise.all(paramsArray.map((params) => JobsAPI.checkDuplicate(params)));

      this.existsJobs = jobFromApi.map(({ found = [] }, index) => {
        const haveDuplicate = found.length ? true : false;
        return {
          jobIndex: index + 1,
          job: haveDuplicate ? found[0] : {},
          haveDuplicate,
          id: haveDuplicate ? found[0].id : undefined,
        };
      });
      this.setState({ existJobsChecked: true, processing: false });
      return;
    } catch (error) {
      showErrorMessage(error);
      this.setState({ existJobsChecked: true, processing: false });
      this.existsJobs = [{ jobIndex: 0, job: {}, haveDuplicate: false, id: 0 }];
    }
  };

  unsetExistJob = (jobIndex = 0, callback = () => {}) => {
    this.existsJobs = this.existsJobs.map((job) =>
      job.jobIndex === jobIndex ? { ...job, haveDuplicate: false } : job
    );
    const updatedJobs = this.state.jobs.map((job, index) => ({
      ...job,
      confirmedDuplicate: this.existsJobs[index].id ? this.existsJobs[index].id : job.confirmedDuplicate,
    }));
    this.setState({ jobs: updatedJobs }, callback);
  };

  showAllExistJobs = () => {
    this.existsJobs.forEach(({ jobIndex, job, haveDuplicate }) => {
      if (!haveDuplicate) return;

      const structure = job.locations[0].structure ? ', structure ' + this.getStructuresString(job.locations, '#') : '';
      confirmAlert({
        title: `Potential duplicate Job #${jobIndex} found:`,
        message: `${JobType[job.jobType]} Conf #${job.id} for ${job.maxWorkers} at ${
          job.locations[0].address
        }${structure} at ${moment(job.requestTime).local().format('M/D/YYYY HH:mm')} for ${
          job.departmentName
        } requested by ${job.requestorName}`,
        buttons: [
          {
            label: 'Cancel',
            onClick: (hideAlert) => {
              hideAlert();
            },
            btnType: 'success',
          },
          {
            label: 'Open Existing Job',
            onClick: () => window.open(`/job/${job.id}`, '_blank'),
            btnType: 'success',
          },
          {
            label: 'Submit Anyway',
            onClick: (hideAlert) => {
              this.unsetExistJob(jobIndex, () => {
                hideAlert();
                this.saveJobs();
              });
            },
            btnType: 'primary',
          },
        ],
      });
    });
    this.setState({ processing: false });
  };

  saveJobs = async () => {
    if (!this.state.existJobsChecked) {
      await this.checkExistsJob();
    }

    const haveExistsJobs = this.existsJobs.some(({ haveDuplicate }) => haveDuplicate);
    if (!haveExistsJobs) {
      this.setState({ processing: true });
      this.props.setFieldValue('jobs', this.state.jobs, false);
      this.props.handleSubmit();
      this.setState({ existJobsChecked: false, processing: false });
      return;
    }

    this.showAllExistJobs();
  };

  goBack = () => {
    app_history.goBack();
  };

  onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  changeJobIndex = (index) => {
    this.setState({ selectedIndex: index });
    this.handleClickScroll(index);
  };

  getStructuresString = (locationsList = [], numberSign = '') => {
    if (!locationsList.length) {
      return '';
    }
    return locationsList
      .map((locationItem) => (locationItem.structure ? numberSign + locationItem.structure : ''))
      .join(', ');
  };

  public render() {
    const { errors, touched, handleBlur, isValid, validateForm } = this.props;
    const userCanCloneJob = UserPermissions.has.can_clone_job;

    return (
      <div className="JobCreate pt-4 pb-4" style={{ overflowY: 'scroll', width: '100%' }}>
        <JobsLeftBlock
          jobs={this.state.jobs}
          onSave={this.onSubmit}
          handleBlur={handleBlur}
          isValid={isValid}
          selectedIndex={this.state.selectedIndex}
          processing={this.props.processing || this.state.processing}
          errors={errors ? errors : { jobs: [] }}
          changeJob={this.changeJobIndex}
          deleteJob={(event) => this.removeJob(event)}
          addAnotherJob
          addNewJob={this.addNewJob}
          showErrors={this.state.duplicatesChecked}
          addCheckDuplicates={true}
          checkDuplicates={this.checkDuplicateJobs}
          duplicates={this.state.duplicates}
          //isEdit
        />
        <div
          style={{
            position: 'relative',
          }}
        >
          <form className="template-wrapper" onKeyDown={this.onKeyDown}>
            {this.state.jobs.map((job, index) => {
              const isCopy = this.props.match.url.includes('copy');
              return (
                <div key={`job-${index}`} ref={(instance) => (this.myRef[index] = instance)}>
                  <JobFormComponent
                    onJobFormChange={this.onJobFormChange}
                    index={index}
                    job={job}
                    remove={true}
                    touchedSubmit={touched ? touched : false}
                    errors={errors}
                    handleBlur={handleBlur}
                    removeJob={(event) => this.removeJob(event)}
                    validateForm={validateForm}
                    can_edit_location={true}
                    isEdit={false}
                    isCopy={isCopy}
                  />
                </div>
              );
            })}

            <Box display="flex" gap={1}>
              <Button startIcon={<AddCircleIcon />} onClick={() => this.addNewJob()}>
                Add Another Job
              </Button>
              {userCanCloneJob && (
                <Button
                  startIcon={<AddCircleIcon />}
                  onClick={() => this.addNewJob(this.state.jobs[this.state.jobs.length - 1])}
                >
                  Add Job & Copy Above
                </Button>
              )}
            </Box>
          </form>
        </div>

        <WarningDialog
          open={this.state.showDuplicateAlert}
          close={() => this.setState({ showDuplicateAlert: false })}
          text={
            <div>
              There are duplicates jobs:
              <ul className="warning-list">
                {this.state.duplicates.map((dupJob) => (
                  <li key={dupJob.index} className="warning-list-item">
                    <p className="compare-item">
                      <span>
                        Job #{dupJob.index + 1} Location:
                        <span className={dupJob.adressDuplicate ? 'duplicate' : ''}> {dupJob.adress}</span>;
                      </span>
                      <span className={dupJob.structureDuplicate ? 'duplicate' : ''}>
                        Structure: {dupJob.structure}
                      </span>
                    </p>
                    <div className="divider" />
                    <p className="compare-item">
                      <span>
                        Job #{dupJob.uniqueIndex + 1} Location: {dupJob.uniqueAdress};
                      </span>
                      <span>Structure: {dupJob.uniqueStructure}</span>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          }
          confirmTitle={'Remove duplicate'}
          confirmTooltipTitle="This action will only remove jobs from the left column"
          confirmWidth={'200px'}
          skipButtonText="Skip & Confirm"
          skip={this.confirmDuplicates}
          confirm={this.removeDuplicates}
          dialogProps={{ maxWidth: 'md', fullWidth: true }}
        />
      </div>
    );
  }
}

const formatter = (array: any[]) =>
  array.map((item) => {
    if (item.requestor) {
      item.requestor = item.requestor.id;
    }
    if (item.supervisor) {
      item.supervisor = item.supervisor.id;
    }
    if (item.department) {
      item.department = item.department.id;
    }
    if (item.ccUser) {
      const ccUser = [];
      item.ccUser.map((_item) => ccUser.push(_item.value ? _item.value.id : _item.id));
      item.ccUser = [...ccUser];
    }
    return item;
  });

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    runJobProcessing: () => dispatch({ type: JOB_CREATE_REQUEST }),
    createJob: (data) => dispatch(actions.JobsActions.createJob(data)),
    uploadImages: (images) => dispatch(actions.JobsActions.uploadImages(images)),
    updateFilters: (filter) => dispatch(actions.JobsActions.updateFilters(filter)),
  };
}

function mapStateToProps(state: ReduxState) {
  return {
    processing: state.jobs.create_job_processing,
    job: state.jobs.job,
    departments: state.app.departments,
    departmentGroups: state.app.departmentGroups,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    mapPropsToValues: (props: any) => {
      return {
        ...props,
      };
    },
    validationSchema: ({ departmentGroups }) => {
      const ignoreRequestTimeLimitation = UserPermissions.has.create_signage_jobs_without_request_time_limitation;
      const SIDepartments = departmentGroups?.find(({ id }) => id === departmentsGroupID.SI_Depts)?.departments;

      return JobCreateValidation.shape({
        jobs: Yup.array().of(
          jobSchema.shape({
            requestTime: requestTimeValidation.when(
              ['jobType', 'department'],
              ([jobType, department]: [JobType, Department], schema: Yup.DateSchema<Date>) => {
                if (ignoreRequestTimeLimitation || jobType !== JobType.Signage) {
                  return schema;
                }

                const minimumHoursFromNow = SIDepartments?.some(({ id }) => id === department?.id) ? 24 : 72;
                return schema.min(
                  moment().add(minimumHoursFromNow, 'hours').toDate(),
                  `Start Time must be at least ${minimumHoursFromNow} hours from now.`
                );
              }
            ),
          })
        ),
      });
    },

    handleSubmit: (values: any, { props }) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          props?.runJobProcessing();
          for (let i = 0; i < values.jobs.length; i++) {
            for (let x = 0; x < values.jobs[i].locations.length; x++) {
              const location = values.jobs[i].locations[x];
              if (location.images && location.images.length > 0) {
                const imageFiles = new FilesUpload(location.images, 'image_');

                if (!imageFiles.isAllFilesUploaded) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  await imageFiles.uploadNew(JobsAPI.uploadImages);
                }
                values.jobs[i].locations[x].files = imageFiles.allUploaded;
              }
            }

            // eslint-disable-next-line no-prototype-builtins
            if (values.jobs[i].hasOwnProperty('jobPdfs')) {
              const pdfFiles = new FilesUpload(values.jobs[i].jobPdfs, 'pdfs_');
              if (!pdfFiles.isAllFilesUploaded) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                await pdfFiles.uploadNew(JobsAPI.uploadImages);
              }
              values.jobs[i].jobPdfs = pdfFiles.allUploaded;
            }
          }
          const jobs = JSON.parse(JSON.stringify(values));
          const data = {
            jobs: formatter([...jobs.jobs]),
          };

          props
            .createJob(data)
            .then((job) => {
              localStorage.removeItem('JobsTemp');
              localStorage.removeItem('duplicateJob');
              if (data.jobs.length > 1) {
                props.updateFilters({ ...initialSearch });
                app_history.push(`/job-grid`);
              } else {
                app_history.push(`/job/${job.id}`);
              }
            })
            .catch((err) => {
              toast.error(err.error, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });

          //
        } catch {
          return reject();
        }
      });
    },
  })(JobCreateComponent)
);
