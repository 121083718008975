const Fonts = {
  Poppins: {
    400: 'Poppins Regular',
    500: 'Poppins Medium',
    600: 'Poppins SemiBold',
    700: 'Poppins Bold',
  },
};

export const Poppins = Fonts.Poppins;

export default Fonts;
