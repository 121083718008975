import React, { Component, CSSProperties } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { CloseRounded } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';

import { TIMESHEETS_TABLE } from 'Constants/invoices';
import AppPermissions from 'Constants/permissions';
import { actions } from 'Services';
import { copyWithInfo } from 'Utils/copy';
import AppTablePagination from 'components/AppTablePagination/AppTablePagination';
import { ReduxState } from 'createStore';

import '../Invoices.scss';
import MarkTimesheetsAsPaid from '../dialog/MarkTimesheetsAsPaid';
import './Timesheets.scss';

interface Props extends ReduxProps {
  invoice_id: number;
  config_id: number;
  onRemoveTimesheet: (timesheet_id: number) => void;
}
class TimesheetsTableRow extends Component<Props, { selectedId: number }> {
  state = {
    selectedId: -1,
  };

  openModal =
    (id = 0) =>
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      this.setState({ selectedId: id });
    };

  closeModal = () => this.setState({ selectedId: -1 });

  onPerPageChange = (limit: number) => {
    const search_option = { page: 1, limit };
    this.props.getTimesheets(this.props.config_id, this.props.invoice_id, search_option);
  };

  onPaginationChange = (event, page) => {
    const search_option = { page };
    this.props.getTimesheets(this.props.config_id, this.props.invoice_id, search_option);
  };

  viewTimesheet =
    (timesheet_id = 0) =>
    () => {
      window.open(`/timesheets/${timesheet_id}/edit`);
    };

  handleSort = (value) => () => {
    const { order_by, order_by_type } = this.props.pagination;
    const { config_id, invoice_id } = this.props;
    if (order_by === value) {
      this.props.getTimesheets(config_id, invoice_id, {
        order_by: value,
        order_by_type: !order_by_type,
      });
    } else {
      this.props.getTimesheets(config_id, invoice_id, {
        order_by: value,
        order_by_type: true,
      });
    }
  };

  HeaderCell =
    (rowSpan = 1, style?: CSSProperties) =>
    ({ label = '', value = '', sortable = false }) => {
      const { order_by, order_by_type } = this.props.pagination;
      if (sortable) {
        return (
          <TableCell key={value} rowSpan={rowSpan} style={style}>
            <TableSortLabel
              active={order_by === value}
              direction={order_by === value && order_by_type ? 'desc' : 'asc'}
              onClick={this.handleSort(value)}
            >
              {label}
            </TableSortLabel>
          </TableCell>
        );
      } else {
        return (
          <TableCell key={value} rowSpan={rowSpan} style={style}>
            {label}
          </TableCell>
        );
      }
    };

  render() {
    const { timesheets, canEditInvoice } = this.props;
    const { config_id, invoice_id, storedConfigId, storedInvoiceId } = this.props;
    const { page, limit, total } = this.props.pagination;
    const isTheSameTimesheetList = config_id === storedConfigId && invoice_id === storedInvoiceId;
    const secondRowHeaderFirstIndex = TIMESHEETS_TABLE.findIndex(({ values }) => values?.length);
    return (
      <Paper className="invoice_timesheets-paper">
        <TableContainer>
          <Table stickyHeader>
            <TableHead style={{ background: '#fff' }}>
              <TableRow>
                {TIMESHEETS_TABLE.map((headCell, index) => {
                  if (headCell.value === '' && !canEditInvoice) return null;
                  if (headCell.values?.length) {
                    return (
                      <TableCell
                        colSpan={headCell.values.length}
                        key={headCell.label + headCell.values.length + index}
                        padding="none"
                        align="center"
                        style={{ padding: 4 }}
                      >
                        {headCell.label}
                      </TableCell>
                    );
                  }
                  return this.HeaderCell(2)(headCell);
                })}
              </TableRow>
              {secondRowHeaderFirstIndex !== -1 ? (
                <TableRow>
                  {TIMESHEETS_TABLE[secondRowHeaderFirstIndex].values?.map(this.HeaderCell(1, { padding: 4, top: 33 }))}
                </TableRow>
              ) : null}
            </TableHead>
            {timesheets && timesheets.length > 0 && isTheSameTimesheetList ? (
              <TableBody>
                {timesheets.map((row) => (
                  <TableRow hover style={{ cursor: 'pointer' }} key={row.id} onClick={this.viewTimesheet(row.id)}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.start_at}</TableCell>
                    <TableCell>{row.finish_at}</TableCell>
                    <TableCell>{row.department}</TableCell>
                    <TableCell>{row.l2_org}</TableCell>
                    <TableCell>{row.l2_code}</TableCell>
                    <TableCell>{row.section}</TableCell>
                    <TableCell>
                      <Tooltip disableInteractive followCursor title="Click to copy PO #" placement="top">
                        <span style={{ cursor: 'copy' }} onClick={copyWithInfo(row.po, `${row.po} was copied`)}>
                          {row.po}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{row.requestor}</TableCell>
                    <TableCell>{row.request_time}</TableCell>
                    <TableCell>{row.supervisor}</TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell>{row.muni}</TableCell>
                    <TableCell>{row.flagger_name}</TableCell>
                    <TableCell align="center">{row.regular_hours}</TableCell>
                    <TableCell align="center">{row.overtime_hours}</TableCell>
                    <TableCell align="center">{row.holiday_hours}</TableCell>
                    <TableCell align="center">{row.emergency_hours}</TableCell>
                    <TableCell align="center">{row.billed ? <CheckIcon style={{ color: '#02b337' }} /> : ''}</TableCell>
                    {canEditInvoice && (
                      <TableCell>
                        <div className="d-flex">
                          <Tooltip
                            disableInteractive
                            title="Mark Timesheet as Paid and/or Change Job's PO#"
                            aria-label={row.id}
                            arrow
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div className={'table-action_img-btn'} onClick={this.openModal(row.id)}>
                                <DoneAllIcon style={{ color: 'black' }} />
                              </div>
                            </div>
                          </Tooltip>
                          {!row.billed && (
                            <Tooltip disableInteractive title="Remove Timesheet from the invoice" arrow>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                  className={'table-action_img-btn'}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.onRemoveTimesheet(row.id);
                                  }}
                                >
                                  <CloseRounded style={{ color: 'black' }} />
                                </div>
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={15} align={'center'} style={{ fontWeight: 500, fontSize: 14 }}>
                    No timesheets yet
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {this.state.selectedId !== -1 && (
          <MarkTimesheetsAsPaid
            onClose={this.closeModal}
            open={this.state.selectedId !== -1}
            timesheet={this.state.selectedId}
            singleTimesheetMode
            config_id={this.props.config_id}
          />
        )}
        {this.props.pagination && (
          <AppTablePagination
            page={typeof page === 'number' ? page : parseInt(page)}
            total={total}
            perPage={typeof limit === 'number' ? limit : parseInt(limit)}
            onChangePage={this.onPaginationChange}
            onPaginationChange={this.onPerPageChange}
          />
        )}
      </Paper>
    );
  }
}
function mapStateToProps({ invoices, app }: ReduxState) {
  return {
    timesheets: invoices.timesheets,
    pagination: invoices.timesheet_pagination,
    canEditInvoice: app.permissions.includes(AppPermissions.edit_invoices),
    storedInvoiceId: Number(invoices.timesheets_invoice_id),
    storedConfigId: Number(invoices.invoices_config_id),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getTimesheets: (
      conf_id: string | number,
      invoice_id: string | number,
      search_options?: Partial<ReduxState['invoices']['timesheet_pagination']>
    ) => dispatch(actions.InvoicesActions.getTimesheets(conf_id, invoice_id, search_options)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(TimesheetsTableRow);
