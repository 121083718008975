import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import clsx from 'clsx';
import moment from 'moment';

import { Box, Tooltip } from '@mui/material';

import { Job } from 'types/Job';

import { JobStatus, JobType, JobTypeHelper } from 'Constants/job';
import { requestorLetter } from 'Constants/letter';
import { FORMATS } from 'Utils/Date';
import UserPermissions from 'Utils/PermissionsHelper';
import { canVerifyJob } from 'Utils/job';
import app_history from 'app_history';
import { ReduxState } from 'createStore';

import CheckboxComponent from '../Components/Controls/Checkbox.Component';
import './JobItemShedule.scss';
import WorkerGroup from './RerouteWorker/WorkerGroup';
import VerificationCheckboxWithTooltip from './components/VerificationCheckboxWithTooltip';

type Props = ReduxProps & {
  job: Job;
  selectable?: boolean;
  checked?: boolean;
  reroutable?: boolean;
  onCheckboxChange?: (checked: boolean) => void;
  search?: () => void;
};

class JobItemSchedule extends React.Component<Props> {
  static defaultProps = {
    selectable: true,
  };

  gotoDetails = () => {
    app_history.push(`/job/${this.props.job.id}`);
  };

  render() {
    const { job, isUserSubcontractor } = this.props;
    const { isSignage } = new JobTypeHelper(job.jobType);
    const userCanVerifyJob =
      UserPermissions.has.can_verify_jobs && canVerifyJob(job.jobType, job.jobStatus, job.coned_imported);

    return (
      <div className="box-item-body hover-item-body cursor-pointer radius">
        <div>
          <div className="header-item" onClick={this.gotoDetails}>
            <Box display="flex" alignItems="center">
              <div className="d-flex align-items-center">
                {this.props.selectable ? (
                  <CheckboxComponent
                    onChange={this.props.onCheckboxChange}
                    checked={this.props.checked}
                    id={`job-checkbox-${job.id}`}
                    hasTitle={`${JobType[job.jobType]} #${job.id}`}
                    color="#333333"
                  />
                ) : (
                  <span>{`${JobType[job.jobType]} #${job.id}`}</span>
                )}
              </div>
              {userCanVerifyJob && (
                <VerificationCheckboxWithTooltip verified={!!job.verified} verifierName={job.verifierName} hideEmpty />
              )}
            </Box>
            <div className="d-flex">
              <div className="text-right">
                <div className="job-status">{JobStatus.parsedFromNumber(Number(job.jobStatus))}</div>
              </div>
            </div>
          </div>
          <div className={clsx('job-details', { 'with-effective-dates': isSignage })} onClick={this.gotoDetails}>
            <div className="job-details_item">
              <div className="label" style={{ textTransform: 'capitalize' }}>
                Request Time & date
              </div>
              <div className="text-14-500">{moment(job.requestTime).format(FORMATS.datetime)}</div>
            </div>
            {isSignage ? (
              <>
                <div className="job-details_item">
                  <div className="label" style={{ textTransform: 'capitalize' }}>
                    Effective Start Date
                  </div>
                  {job.effective_start_at ? (
                    <div className="text-14-500">{moment(job.effective_start_at).format(FORMATS.datetime)}</div>
                  ) : null}
                </div>
                <div className="job-details_item">
                  <div className="label" style={{ textTransform: 'capitalize' }}>
                    Effective Finish Date
                  </div>
                  {job.effective_finish_at ? (
                    <div className="text-14-500">{moment(job.effective_finish_at).format(FORMATS.datetime)}</div>
                  ) : null}
                </div>
              </>
            ) : null}

            <div className="job-details_item">
              <div className="label" style={{ textTransform: 'capitalize' }}>
                Supervisor
              </div>
              <div className="text-14-500">{job.supervisorName ? job.supervisorName : '—'}</div>
            </div>

            <div className="job-details_item">
              <div className="label" style={{ textTransform: 'capitalize' }}>
                Department
              </div>
              <div className="text-14-500">{job.departmentName}</div>
            </div>

            <div className="job-details_item">
              <div className="label" style={{ textTransform: 'capitalize' }}>
                Requestor
              </div>
              <div className="text-14-500">
                {isUserSubcontractor ? (
                  <span>{job.requestorName}</span>
                ) : (
                  <a
                    href={requestorLetter(job.requestorEmail, job.requestorName, job.confirmationNumber, job.locations)}
                  >
                    {job.requestorName}
                  </a>
                )}
              </div>
            </div>

            <div className="w-100 job-info-right job-max-workers">
              <div className="label" style={{ textTransform: 'capitalize' }}>
                Max Workers
              </div>
              <div className="text-14-500">{job.maxWorkers}</div>
            </div>
            <Tooltip title={job.po ? job.po : ''} arrow aria-label="po">
              <div className="w-100 job-info-right">
                <div className="label">PO#</div>
                <div className="text-14-500">
                  {job.po && job.po.length > 12 ? `${job.po.slice(0, 11)}...` : job.po}
                  {'\u00A0'}
                </div>
              </div>
            </Tooltip>
          </div>
        </div>

        {this.props.reroutable && (
          <WorkerGroup
            jobId={this.props.job.id}
            onSaveSuccess={this.props.search}
            jobDetail={this.props.job}
            selectRow={() => {}}
            isJobStandby={Boolean(this.props.job.is_standby)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    isUserSubcontractor: state.app.userIsSubcontractor,
  };
}

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(JobItemSchedule);
