import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import moment from 'moment';
import { ImmutableArray } from 'seamless-immutable';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { ArchivedImage } from 'types/Job';
import { ImageWithAuthor } from 'types/Location';

import ImageViewer from 'Containers/Components/ImageViewer/ImageViewer';
import { actions } from 'Services';
import { AppAPI } from 'Services/API';
import { Poppins } from 'Utils/Fonts';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal';

type Props = {
  open: boolean;
  onClose: () => void;
  archivedImagesList: ImmutableArray<ArchivedImage>;
};

const ArchivedImagesModal: FC<Props> = ({ open, onClose, archivedImagesList }) => {
  const [imageToRestore, setImageToRestore] = useState<
    | ({
        id: number;
      } & ImageWithAuthor)
    | null
  >(null);
  const { id: jobID } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { inProcess: restoringInProgress, promiseWrapper } = useProcessing();

  const closeConfirmationModal = () => setImageToRestore(null);

  const restorePhoto = useCallback(async () => {
    try {
      await AppAPI.restoreFileByID(imageToRestore.id);
      await dispatch(actions.JobsActions.retrieveJob(jobID));

      showSuccessMessage('Image restored successfully');
    } catch (error) {
      showErrorMessage(error);
    } finally {
      closeConfirmationModal();
    }
  }, [dispatch, imageToRestore, jobID]);

  useEffect(() => {
    if (!archivedImagesList.length) {
      onClose();
    }
  }, [archivedImagesList]);

  return (
    <AppPaperModal
      containerStyle={{ width: '100%', maxWidth: 700 }}
      open={open}
      onClose={onClose}
      title={'Archived Images'}
      subtitle="Deleted images that can be restored."
      noActions
    >
      <Stack spacing={2}>
        {[...archivedImagesList].map(({ date, files: images }, index) => (
          <Stack key={date} spacing={2}>
            {index !== 0 && <Divider />}
            <Box>
              <Typography>
                Deleted:{' '}
                <Typography component="span" fontFamily={Poppins[500]} fontStyle="italic">
                  {moment(date).format('MMMM DD, YYYY')}
                </Typography>
              </Typography>
            </Box>
            <Box>
              <ImageViewer
                canSaveRotate={false}
                images={images}
                showImagesRow
                canRestorePhoto
                onRestorePhoto={(image) => setImageToRestore(image)}
              />
            </Box>
          </Stack>
        ))}
      </Stack>

      {!!imageToRestore && (
        <AppPaperModal
          containerStyle={{ width: '100%', maxWidth: 400 }}
          modalId={'restore-image-confirmation'}
          open={!!imageToRestore}
          onClose={closeConfirmationModal}
          submitButton={{
            onClick: () => promiseWrapper(restorePhoto()),
            title: 'Restore',
            loading: restoringInProgress,
          }}
          cancelButton={{
            onClick: () => closeConfirmationModal(),
          }}
          title="Restore Image"
          subtitle="Are you sure you want to restore this image?"
        >
          <Typography>{imageToRestore.title}</Typography>
        </AppPaperModal>
      )}
    </AppPaperModal>
  );
};

export default ArchivedImagesModal;
