import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';

import { Box, Divider } from '@mui/material';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import { AppThunkDispatch } from 'types';
import { Job as JobType } from 'types/Job';

import FilterComponent from 'Containers/Searchs/Filter.Component';
import { actions } from 'Services';
import { UpdatePOSData } from 'Services/API/JobsAPI.service';
import UserPermissions from 'Utils/PermissionsHelper';
import { showErrorMessage } from 'Utils/errorMessage';
import { canVerifyJob } from 'Utils/job';
import { showSuccessMessage } from 'Utils/successMessage';
import app_history from 'app_history';
import AppTablePagination from 'components/AppTablePagination/AppTablePagination';
import Search from 'components/Search/Search';
import UpdatePOButton from 'components/UpdatePOButton/UpdatePOButton';
import { ReduxState } from 'createStore';

import './Job.scss';
import JobItemSchedule from './JobItemSchedule';
import VerifyJobsButton from './components/VerifyJobsButton';
import UpdatePODialog from './dialogs/UpdatePODialog';
import VerifyJobsModal from './dialogs/VerifyJobsModal';

type State = {
  searchParams: any;
  initSearch: boolean;
  page: number;
  perPage: number;
  po: number;
  selectedJobs: { [key: string]: JobType };
  showUpdatePODialog: boolean;
  unconfirmedItemsCountPerPage: number;
  confirmedItemsCountPerPage: number;
  showFilter: boolean;
  verifyJobsModalOpen: boolean;
};

class Job extends React.Component<ReduxProps, State> {
  state = {
    searchParams: { page: 0, search: '' },
    initSearch: true,
    page: 0,
    perPage: 10,
    po: 100101,
    selectedJobs: {} as { [key: string]: JobType },
    showUpdatePODialog: false,
    unconfirmedItemsCountPerPage: 1,
    confirmedItemsCountPerPage: 1,
    showFilter: true,
    verifyJobsModalOpen: false,
  };
  jobList: boolean;
  isToggleModal: boolean;
  ref = React.createRef<HTMLDivElement>();

  static getDerivedStateFromProps(props, state) {
    if (state.initSearch) {
      return {
        searchParams: { ...state.searchParams, ...props.search_options },
        initSearch: false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: ReduxProps) {
    if (prevProps.search_options !== this.props.search_options) {
      this.setState({ selectedJobs: {} });
    }
  }

  showModal = () => {
    if (!Object.keys(this.state.selectedJobs).length) {
      showErrorMessage('Please select any job');
      return;
    }
    this.setState({
      showUpdatePODialog: true,
    });
  };

  createJob = () => {
    app_history.push('/job/create');
  };

  closeModal = () => {
    this.setState({
      showUpdatePODialog: false,
    });
  };

  search = async (params: any, keepPage = false) => {
    const searchParams = { ...params, ...(!keepPage && { page: 0 }) };
    this.setState({ searchParams, page: 0 });
    this.onChange(searchParams);
  };

  onChange = debounce((searchParams) => {
    this.props.updateFilters(searchParams);
  }, 700);

  changedPage = (page: number) => {
    this.setState({
      searchParams: { ...this.state.searchParams, page: page },
      page: page,
    });
    this.props.updateFilters({ ...this.state.searchParams, page: page });
  };

  updatePOS = (posData: UpdatePOSData) => {
    this.props
      .updatePOS(posData)
      .then((updatedFields) => {
        this.setState({
          showUpdatePODialog: false,
          selectedJobs: {},
        });
        showSuccessMessage(`${updatedFields} updated successfully!`);
      })
      .catch((error) => {
        let msg = error.error;
        if (msg.includes('SQL')) {
          msg = 'Error! Your data is invalid. The maximum limit is 30 characters or numbers.';
        }
        toast.error(msg, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  handleSelectJob = (job: JobType) => {
    this.setState(({ selectedJobs }) => {
      if (selectedJobs[job.id]) {
        const { [job.id]: omit, ...rest } = selectedJobs;
        return { selectedJobs: rest };
      }
      return {
        selectedJobs: {
          ...selectedJobs,
          [job.id]: job,
        },
      };
    });
  };

  toggleFilter = () => {
    this.setState((state: any) => ({ showFilter: !state.showFilter }));
  };

  onPaginationChangeUnconfirmedJobs = (page: number) => {
    this.setState({ unconfirmedItemsCountPerPage: page });
  };

  onPaginationChangeConfirmedJobs = (event, page: number) => {
    this.setState({ confirmedItemsCountPerPage: page });
    if (this.ref.current) {
      this.ref.current.scrollTop = 0;
    }
  };

  changePerPage = (perPage = 10) => {
    this.setState({ perPage, confirmedItemsCountPerPage: 1 });
  };

  openVerifyJobsModal = () => {
    this.setState({ verifyJobsModalOpen: true });
  };

  closeVerifyJobsModal = () => {
    this.setState({ verifyJobsModalOpen: false });
  };

  render() {
    const props = this.props;
    const jobs = props.jobs;
    const hasSupervisor = this.props.is_supervisor;
    const userCanDoJobAction = UserPermissions.has.can_do_job_action;

    const jobs_pagination = [];
    jobs.forEach((job, index) => {
      if (
        this.state.confirmedItemsCountPerPage > 1
          ? index >= (this.state.confirmedItemsCountPerPage - 1) * this.state.perPage
          : index >= this.state.confirmedItemsCountPerPage - 1
      ) {
        if (index < this.state.confirmedItemsCountPerPage * this.state.perPage) {
          jobs_pagination.push(job);
        }
      }
    });

    const selectedJobs = Object.values(this.state.selectedJobs);

    const areSelectedJobsVerified = selectedJobs?.length && selectedJobs.every(({ verified }) => Boolean(verified));
    const canVerifyJobs =
      selectedJobs?.length &&
      selectedJobs.every(({ jobStatus, jobType, coned_imported }) => canVerifyJob(jobType, jobStatus, coned_imported));

    const verifyButtonToggleLabel = areSelectedJobsVerified
      ? `Unverify ${selectedJobs.length > 1 ? 'Jobs' : 'Job'}`
      : `Verify ${selectedJobs.length > 1 ? 'Jobs' : 'Job'}`;

    return (
      <>
        {this.props.processing ? <LinearProgress /> : <div style={{ height: 4 }}></div>}
        <div className="d-flex" id={'jobs-grid'}>
          <FilterComponent
            hasDepartment={true}
            hasRequestDate={true}
            hasCreateDate
            hasJobStatus={true}
            hasWorker={true}
            hasRequestor={!this.props.userIsSubcontractor}
            hasNumber={true}
            hasAddress={true}
            hasBorough={true}
            hasFilter
            hasFieldSupervisor={hasSupervisor}
            showFilter
            showSearch={false}
            hasSort
            numberMonth={1}
            //  search={this.search}
            onFilter={(_) => {
              this.jobList = true;
              this.setState({ confirmedItemsCountPerPage: 1 }, () =>
                console.log(this.state.confirmedItemsCountPerPage)
              );
            }}
          />
          <div className="col-right job-list-page">
            <div className="buttons-row align-items-center">
              <Search
                updateFilters={this.search}
                placeholder={'Search ...'}
                instant={true}
                search_value={this.state.searchParams.search ? this.state.searchParams.search : ''}
              />
              {UserPermissions.has.can_verify_jobs && (
                <VerifyJobsButton
                  disabled={!canVerifyJobs}
                  onClick={this.openVerifyJobsModal}
                  verified={areSelectedJobsVerified}
                >
                  {verifyButtonToggleLabel}
                </VerifyJobsButton>
              )}
              {userCanDoJobAction && (
                <Box display="flex" alignItems="center" px="10px" gap={'4px'}>
                  <UpdatePOButton onClick={this.showModal} disabled={!Object.keys(this.state.selectedJobs).length} />

                  <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

                  <Button variant="contained" onClick={this.createJob} disableRipple>
                    Create Job
                  </Button>
                </Box>
              )}

              {this.state.verifyJobsModalOpen && (
                <VerifyJobsModal
                  open={this.state.verifyJobsModalOpen}
                  onClose={this.closeVerifyJobsModal}
                  onSuccessfulVerification={() => {
                    this.search(this.state.searchParams, true);
                    this.setState({ selectedJobs: {} });
                  }}
                  selectedJobs={selectedJobs}
                />
              )}
            </div>

            <UpdatePODialog
              open={this.state.showUpdatePODialog}
              close={this.closeModal}
              updatePOS={this.updatePOS}
              receiptUpdate
              selectedJobs={Object.values(this.state.selectedJobs) || []}
            />

            <div
              className={'jobs-table'}
              style={{
                overflowY: 'scroll',
                flex: 1,
                overflowX: 'hidden',
                width: '100%',
                paddingTop: '10px',
              }}
              ref={this.ref}
            >
              {jobs_pagination.map((job, index) => (
                <JobItemSchedule
                  reroutable
                  job={job}
                  key={index}
                  checked={!!this.state.selectedJobs[job.id]}
                  onCheckboxChange={() => this.handleSelectJob(job)}
                  search={() => this.search(this.state.searchParams, true)}
                />
              ))}
            </div>

            <AppTablePagination
              page={this.state.confirmedItemsCountPerPage}
              total={this.props.total}
              perPage={this.state.perPage}
              onChangePage={this.onPaginationChangeConfirmedJobs}
              onPaginationChange={this.changePerPage}
            />
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    processing: state.jobs.processing,
    total: state.jobs.jobs ? state.jobs.jobs.total : 0,
    jobs: state.jobs.jobs.results,
    stats: state.jobs.stats,
    confirm_jobs_processing: state.jobs.confirm_jobs_processing,
    can_see_unconfirmed_jobs: state.app.user ? [5, 6, 7, 8].some((r) => state.app.user.roles.includes(r)) : false,
    is_supervisor: state.app.user ? [3].some((r) => state.app.user.roles.includes(r)) : false,
    search_options: state.jobs.search_options,
    userIsSubcontractor: state.app.userIsSubcontractor,
  };
}
function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatch,
    updateFilters: (search_options) => dispatch(actions.JobsActions.updateFilters(search_options)),
    confirmJobs: (job_ids) => dispatch(actions.JobsActions.confirmJobs(job_ids)),
    updatePOS: (posData: UpdatePOSData) => dispatch(actions.JobsActions.updatePOS(posData)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Job);
