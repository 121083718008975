import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Typography } from '@mui/material';

import { AppThunkDispatch } from 'types';

import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import { useWaitTimer } from 'Utils/hooks/useWaitTimer';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal';

const DeleteUserModal = ({ onClose, user }) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { timer, runTimer, clearTimer } = useWaitTimer(3);
  const { inProcess: loading, promiseWrapper } = useProcessing();
  const retrieveUsers = () => dispatch(actions.UsersActions.retrieveUsers());
  const deleteUser = (id: number | string) => dispatch(actions.UsersActions.deleteUser(id));

  useEffect(() => {
    runTimer();
    return () => {
      clearTimer();
    };
  }, []);

  return (
    <AppPaperModal
      open
      onClose={onClose}
      modalId="delete-user"
      title="Delete user"
      submitButton={{
        disabled: Boolean(timer),
        loading,
        title: timer ? `Wait...${timer}` : 'Delete',
        color: 'error',
        onClick: () => {
          promiseWrapper(deleteUser(user.id))
            .then(() => {
              showSuccessMessage(`User ${user.name} was deleted`);
              retrieveUsers();
            })
            .catch(showErrorMessage)
            .finally(onClose);
        },
      }}
      cancelButton={{
        title: 'Cancel',
        onClick: onClose,
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={3}>
        <DeleteOutlineIcon sx={{ fontSize: 120 }} color="error" />
        <Typography variant="body1" gutterBottom>
          Are you sure you want to delete user: <strong>{user.name}</strong>?
        </Typography>
      </Box>
    </AppPaperModal>
  );
};

export default DeleteUserModal;
