import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';

import { AppThunkDispatch } from 'types';

import { phoneNumberRegExp } from 'Constants/reg_exp';
import { DatasetTestIDs } from 'Constants/tests';
import { USER_STATUSES_LABELS } from 'Constants/user';
import RolesMaterialAsyncSearch from 'Containers/Components/Controls/RolesMaterialAsyncSearch';
import { updateUsersSearchParams } from 'Services/users/slice';
import UserPermissions from 'Utils/PermissionsHelper';
import useAppMediaQuery from 'Utils/hooks/useAppMediaQuery';
import { useAppSelector } from 'createStore';

const enum dynamicInputNames {
  firstName = 'firstName',
  email = 'email',
  phoneNumber = 'phoneNumber',
}

const readableDynamicNamesMap = {
  [dynamicInputNames.firstName]: 'Name',
  [dynamicInputNames.email]: 'Email',
  [dynamicInputNames.phoneNumber]: 'Phone Number',
};

type RolesFilterProps = {
  sort: { name: string; direction: 'asc' | 'desc' };
  onChangeSort: (sortName: string) => void;
  onChangeSortDirection: (sortDirection: 'asc' | 'desc') => void;
};

const RolesFilter = ({ sort, onChangeSort, onChangeSortDirection }: RolesFilterProps) => {
  const dispatch = useDispatch<AppThunkDispatch>();

  const [searchValue, setSearchValue] = useState('');

  const searchOptions = useAppSelector((state) => state.users.searchParams);
  const userCanSeeAllRoles = UserPermissions.has.see_all_roles;

  const { isDesktop } = useAppMediaQuery();

  const updateSearchOptions = (options) => {
    dispatch(updateUsersSearchParams(options));
  };

  const getSelectedUserStatusNames = (selectedStatuses) =>
    Object.entries(USER_STATUSES_LABELS)
      .reduce((acc, [key, value]) => (selectedStatuses.includes(key) ? [...acc, value] : acc), [])
      .join(', ');

  const changeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const currentDynamicName = useMemo(() => {
    if (phoneNumberRegExp.test(searchValue)) {
      return dynamicInputNames.phoneNumber;
    }
    if (searchValue.includes('@')) {
      return dynamicInputNames.email;
    }
    return dynamicInputNames.firstName;
  }, [searchValue]);

  const normalizeSearchValue = useCallback(
    (value: string) => {
      switch (currentDynamicName) {
        case dynamicInputNames.phoneNumber:
          return value.replace(/[\D]+/g, '').trim();
        default:
          return value.trim();
      }
    },
    [currentDynamicName]
  );

  const handleChangeRoles = (roles) => {
    updateSearchOptions({
      roles: roles.map(({ value: { id } }) => id),
    });
  };

  const handleChangeStatus = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateSearchOptions({
      statuses: event.target.value,
    });
  };

  useEffect(() => {
    const debounceUserSearch = setTimeout(() => {
      updateSearchOptions({
        firstName: '',
        email: '',
        phoneNumber: '',
        [currentDynamicName]: normalizeSearchValue(searchValue),
      });
    }, 300);
    return () => clearTimeout(debounceUserSearch);
  }, [searchValue, currentDynamicName, normalizeSearchValue]);

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        borderRadius: '1rem',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gridGap: 20,
      }}
    >
      <TextField
        sx={{
          minWidth: 200,
          '@media (max-width: 900px)': {
            flex: 1,
          },
        }}
        value={searchValue}
        onChange={changeSearchValue}
        label={`Search by ${searchValue ? readableDynamicNamesMap[currentDynamicName] : 'name, email, phone'}`}
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
        {...DatasetTestIDs.pages.manageRoles.fields.searchInput}
      />

      <RolesMaterialAsyncSearch
        onSelectRole={handleChangeRoles}
        onlyDispatcher={!userCanSeeAllRoles}
        selectedRoleIds={searchOptions.roles}
        small
      />

      <TextField
        select
        label="Status"
        SelectProps={{
          multiple: true,
          renderValue: getSelectedUserStatusNames,
        }}
        sx={{ minWidth: 200, maxWidth: { xs: 'auto', sm: 200 }, '@media (max-width: 900px)': { flex: 1 } }}
        name="statuses"
        onChange={handleChangeStatus}
        value={searchOptions.statuses}
        size="small"
        variant="outlined"
      >
        {Object.entries(USER_STATUSES_LABELS).map(([value, label], index) => (
          <MenuItem key={index} value={value}>
            <Checkbox
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled color="primary" />}
              checked={searchOptions.statuses.includes(value)}
            />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </TextField>

      {!isDesktop && (
        <Box display={'flex'} gap={2}>
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
            <Select
              value={sort.name}
              placeholder="Sort by"
              size="small"
              label="Sort by"
              variant="outlined"
              onChange={(event) => onChangeSort(event.target.value as string)}
            >
              <MenuItem value="id">ID</MenuItem>
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="status">Status</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 70 }}>
            <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
            <Select
              value={sort.direction}
              placeholder="Sort by"
              size="small"
              label="Sort by"
              variant="outlined"
              onChange={(event) => onChangeSortDirection(event.target.value as 'asc' | 'desc')}
            >
              <MenuItem value="asc">ASC</MenuItem>
              <MenuItem value="desc">DESC</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default RolesFilter;
