import { useCallback } from 'react';

import { AsyncPaginate } from 'react-select-async-paginate';

import { ConedUser, UsersSearchParams } from 'types/Common/User';

import { APPROVE, EROLES } from 'Constants/user';
import { UsersAPI } from 'Services/API';

import paginatedSelectStyles from './styled';

const parseUsersOptions = (users: ConedUser[]): UserOptionType[] => {
  return users.map((user) => ({
    label: user.name,
    value: user,
  }));
};

type UserOptionType = {
  label: string;
  value: ConedUser;
};

type PaginateType = {
  page: number;
};

type BaseProps = {
  usersNotAvailable?: number[];
  placeholder?: string;
  params?: UsersSearchParams;
};

type SingleProps = {
  isMulti?: false | undefined | never;
  value: UserOptionType | null;
  onChange: (item: UserOptionType) => void;
};

type MultiProps = {
  isMulti: true;
  value: UserOptionType[];
  onChange: (item: UserOptionType[]) => void;
};

type Props = BaseProps & (SingleProps | MultiProps);

export default function UsersMaterialAsyncSearch({
  onChange,
  value,
  isMulti,
  usersNotAvailable,
  params = {
    per_page: 25,
    include_cc_users: true,
    roles: [EROLES.requestor, EROLES.department_supervisor, EROLES.coned_field_supervisor, EROLES.coned_billing_admin],
  },
  placeholder = 'Search users',
}: Props) {
  const isOptionSelected = useCallback(
    (option: UserOptionType, selectedOptions: UserOptionType[]) =>
      selectedOptions.some((selectedOption) => selectedOption.value.id === option.value.id),
    []
  );

  const loadOptions = useCallback(async (search: string, prevOptions: unknown, { page }: PaginateType) => {
    const { results, has_more_pages: hasMore } = await UsersAPI.getUsers({
      ...params,
      firstName: search,
      page,
    });
    const activeUsersOnly = results.filter((user) => user.isApproved === APPROVE.approved);
    const options = parseUsersOptions(activeUsersOnly);

    const filteredOptions = usersNotAvailable
      ? options.filter((option) => !usersNotAvailable.includes(option.value.id))
      : options;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }, []);

  return (
    <AsyncPaginate
      debounceTimeout={300}
      isOptionSelected={isOptionSelected}
      placeholder={placeholder}
      value={value}
      hideSelectedOptions={false}
      loadOptions={loadOptions}
      isMulti={isMulti}
      closeMenuOnSelect={false}
      onChange={onChange}
      additional={{
        page: 1,
      }}
      styles={paginatedSelectStyles}
    />
  );
}
