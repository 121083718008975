import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ConedUser, UsersSearchParams } from 'types/Common/User';

import { LOGOUT } from 'Services/app/actionTypes';

import { createUser, deleteUser, getUserById, retrieveUsers, updateApprove } from './actions';

const initialState = {
  user: {} as ConedUser,
  users: [] as ConedUser[],
  searchParams: {
    firstName: '',
    email: '',
    phoneNumber: '',
    statuses: [] as string[],
    roles: [] as number[],
    include_cc_users: null as boolean | null,
    page: 1,
    per_page: 10,
    order_by: 'id',
    order_by_type: false,
  } as UsersSearchParams,
  next_page_url: '',
  prev_page_url: '',
  processing: false,
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateUsersSearchParams: (state, { payload }: PayloadAction<UsersSearchParams>) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveUsers.fulfilled, (state, { payload }) => {
        state.users = payload.results;
        state.next_page_url = payload.next_page_url;
        state.prev_page_url = payload.prev_page_url;
        state.processing = false;
      })
      .addCase(getUserById.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.processing = false;
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.processing = false;
      })
      .addCase(deleteUser.fulfilled, (state, { payload: deletedUserId }) => {
        state.users = state.users.filter((user) => user.id !== deletedUserId);
        state.processing = false;
      })
      .addCase(updateApprove.fulfilled, (state, { payload }) => {
        state.users = state.users.map((user) =>
          user.id === payload.id
            ? {
                ...user,
                isApproved: payload.isApproved,
              }
            : user
        );
        state.processing = false;
      })
      .addCase(LOGOUT, () => initialState);

    addLoader(builder, retrieveUsers);
    addLoader(builder, getUserById);
    addLoader(builder, createUser);
    addLoader(builder, deleteUser);
    addLoader(builder, updateApprove);
  },
});

export const UsersReducer = slice.reducer;
export const { updateUsersSearchParams } = slice.actions;

type State = typeof initialState;

function addLoader<T extends ActionReducerMapBuilder<State>>(builder: T, action) {
  return builder
    .addCase(action.pending, (state) => {
      state.processing = true;
    })
    .addCase(action.rejected, (state) => {
      state.processing = false;
    });
}
