import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import DeleteForever from '@mui/icons-material/DeleteForever';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Box, Button, Divider, LinearProgress, Typography } from '@mui/material';

import { addTestID } from 'Constants/tests';
import { TestIDs } from 'Constants/tests';
import { Complaints, ComplaintNote } from 'Models/APITypes';
import { actions } from 'Services';
import { WorkersAPI } from 'Services/API';
import * as CeIcon from 'Utils/Icon';
import UserPermissions from 'Utils/PermissionsHelper';
import { showErrorMessage } from 'Utils/errorMessage';
import useModal from 'Utils/hooks/useModal';
import useProcessing from 'Utils/hooks/useProcessing';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal';
import ChangesHistory from 'components/ChangesHistory';
import { usePopoverContext } from 'context/PopoversContext';
import { useAppDispatch, useAppSelector } from 'createStore';

import AppointedJob from './Details/AppointedJob';
import ComplaintsTab from './Details/ComplaintsTab';
import CreateNewIssueModal from './Details/CreateNewIssueModal';
import WorkerDetailsTabMenu from './Details/WorkerDetailsTabMenu';
import WorkerEditComponent from './WorkerEdit';

export const enum TabContent {
  TabAppointedJobs = 1,
  TabEdit,
  Complaints,
  History,
}

export const enum ComplaintsTypeTab {
  job = 'job',
  worker = 'worker',
}

export const WorkerDetails: FC = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users.user);
  const userProcessing = useAppSelector((state) => state.users.processing);

  const [tab, setTab] = useState(TabContent.TabAppointedJobs);
  const [complaintsTypeTab, setComplaintsTypeTab] = useState(ComplaintsTypeTab.job);
  const [complaints, setComplaints] = useState<{ job: Complaints[]; worker: ComplaintNote[] }>({ job: [], worker: [] });

  const reportIssueModal = useModal();
  const { openPopover, closeAll } = usePopoverContext();
  const { inProcess, promiseWrapper } = useProcessing();

  const canSeeSubcontractors = UserPermissions.has.can_see_subcontractors;
  const canDeleteWorkers = UserPermissions.has.delete_workers;

  const workerDetailsTabContent = useMemo(() => {
    switch (tab) {
      case TabContent.TabAppointedJobs:
        return <AppointedJob />;
      case TabContent.TabEdit:
        return <WorkerEditComponent />;
      case TabContent.Complaints:
        return <ComplaintsTab complaints={complaints} complaintsType={complaintsTypeTab} />;
      case TabContent.History:
        return <ChangesHistory changesLog={user.notes} />;
      default:
        return <WorkerEditComponent />;
    }
  }, [tab, complaintsTypeTab, user.notes, complaints]);

  const fetchUser = (id: string) => dispatch(actions.UsersActions.getUserById(id));

  const fetchComplaints = async (userId: number) => {
    if (!userId) return;
    try {
      const [jobsComplainsResponse, workerComplainsResponse] = await promiseWrapper(
        Promise.allSettled([WorkersAPI.getJobsComplaints(userId), WorkersAPI.getWorkerComplaints(userId)])
      );

      const jobsComplains = jobsComplainsResponse.status === 'fulfilled' ? jobsComplainsResponse.value.result : [];
      const workerComplains = workerComplainsResponse.status === 'fulfilled' ? workerComplainsResponse.value.notes : [];

      setComplaints((prev) => ({ ...prev, job: jobsComplains, worker: workerComplains }));
    } catch (error) {
      showErrorMessage(error);
      setComplaints({ job: [], worker: [] });
    }
  };

  const deleteWorker = async () => {
    try {
      await WorkersAPI.delete(params.id);
      showSuccessMessage('Worker deleted successfully!');
      history.push('/workers');
    } catch (error) {
      showErrorMessage(error);
    } finally {
      closeAll();
    }
  };

  const openDeleteWorkerPopover = () => {
    openPopover(
      'deleteWorkerPopover',
      <AppPaperModal
        modalId="deleteWorkerPopover"
        open
        onClose={closeAll}
        title="Confirm Delete Worker"
        contentStyle={{ maxWidth: '500px' }}
        submitButton={{
          onClick: deleteWorker,
          color: 'error',
          title: 'Remove',
        }}
      >
        <Typography variant="body1">
          If you remove the worker, this worker will be removed from Subcontractor and Jobs, Timesheets.
        </Typography>
        <Typography variant="body1" mt={1}>
          Are you sure to remove worker <b>{user?.name}</b> permanently?
        </Typography>
      </AppPaperModal>
    );
  };

  useEffect(() => {
    if (params.id && params.id !== 'create') fetchUser(params.id);
  }, [params]);

  useEffect(() => {
    if (user?.id) fetchComplaints(user.id);
  }, [user]);

  return (
    <>
      {(userProcessing || inProcess) && <LinearProgress />}

      <div className="d-flex worker-details-page">
        <div className="worker-details-info">
          <div className="box-item-body shadow">
            <div className="d-flex align-items-center border-bottom px-3 py-4">
              <img alt="avatar" src={user?.avatar} className="avatar mr-3" />
              <div>
                <div>{user?.name}</div>
                <div className="cpitalize-text">
                  <span className={`badge badge-success badge-circle worker-${user?.status} mr-2`}>&nbsp;</span>
                  {user?.status}
                </div>
              </div>
            </div>
            <div className="p-3" style={{ wordBreak: 'break-word' }}>
              <div className="item-contact mb-2">
                <CeIcon.EnvelopeSolidIcon className="ce-mr-10" />
                {user?.email}
              </div>
              <div className="item-contact mb-2">
                <CeIcon.PhonesolidIcon className="ce-mr-10" />
                {user?.phoneNumber}
              </div>
              {user?.subcontractorName && canSeeSubcontractors ? (
                <div className="item-contact mb-2">
                  <CeIcon.UserSolidIcon className="ce-mr-10" />
                  {user?.subcontractorName}
                </div>
              ) : null}
            </div>

            <Divider />

            <Box display="flex" flexDirection="column" p={2} gap={1}>
              <Button
                variant="outlined"
                color="warning"
                startIcon={<ReportProblemIcon />}
                onClick={reportIssueModal.open}
                disabled={userProcessing}
              >
                Report New Issue
              </Button>

              {canDeleteWorkers && (
                <Button
                  variant="outlined"
                  startIcon={<DeleteForever />}
                  color="error"
                  onClick={openDeleteWorkerPopover}
                  disabled={userProcessing}
                  {...addTestID(TestIDs.pages.workerProfile.buttons.removeWorker)}
                >
                  Remove Worker
                </Button>
              )}
            </Box>
          </div>
        </div>

        <Box display="flex" flexDirection="column" gap={3} overflow="hidden" width="100%" p="20px">
          <WorkerDetailsTabMenu
            disabled={userProcessing || inProcess}
            tab={tab}
            complaintsTypeTab={complaintsTypeTab}
            onChangeTab={setTab}
            onChangeComplaintsTypeTab={setComplaintsTypeTab}
          />
          {workerDetailsTabContent}
        </Box>
        <CreateNewIssueModal
          closeModal={reportIssueModal.close}
          isOpen={reportIssueModal.isOpen}
          updateComplaintsData={() => fetchComplaints(user?.id)}
        />
      </div>
    </>
  );
};

export default WorkerDetails;
